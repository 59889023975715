.mywishlist_path {
    color: black;
    font-size: 14px;
    font-weight: 200;
}

.mywishlist_path_icon {
    color: var(--second-secondary);
    margin-left: 15px;
    font-size: 14px;
}

@media only screen and (max-width:425px) {
    .mywishlist_path_icon {
        margin-left: 5px;
    }
}

.mywishlist_path_details {
    color: black;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.mywishlist_page_title {
    color: black;
    font-size: 30px;
    font-weight: 400;
}



.mywishlist_main_division {
    box-shadow: 0px 0px 5px 0px black;
    padding: 10px;
}



.mywishlist_mobile_picture {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mywishlist_image {
    height: 130px;
    width: 130px;
    object-fit: cover;
    display: flex;
    align-items: start;
}


.mywishlist_product_name {
    color: var(--second-secondary);
    font-size: 1.2rem;
}

.mywishlist_product_id {
    color: black !important;
}

.mywishlist_price_details {
    display: flex;
    color: black;
}

.mywishlist_product_price {
    color: var(--theme-default-two);
    font-size: 1rem;
    text-decoration: line-through;
}


.mywishlist_product_offer_price {
    color: var(--theme-default-two);
    font-size: 1.4rem;
    margin-right: 15px;
}

.mywishlist_rating_star {
    display: flex;
    color: var(--arihant-color-border-second);
    display: flex;
    align-items: center;
}

.mywishlist_reviews {
    color: black;
    display: flex;
    align-items: center;

}

.mywishlist_show_date_calender {
    display: flex;
    color: black;
}

.mywishlist_calender_icon {
    color: var(--black-background-color);
}

.mywishlist_date {
    color: black;
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
}




.mywishlist-btn-add-to-cart {
    background: var(--theme-default);
    text-transform: capitalize;
    text-align: center;
    color: white;
    padding: 7px;
    border: 1px solid var(--theme-default);
    cursor: pointer;
}

.mywishlist-btn-dalete span {
    background: url(../../../../images/backbtn.png) -553px -95px / 600px no-repeat;
    width: 21px !important;
    height: 21px !important;
    display: inline-block !important;
}


.mywishlist_laptop_division {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.wishlist_icon {
    display: inline-block;
    background: url(../../../../images/backbtn.png) -393px -354px / 500px no-repeat;
    width: 105px;
    height: 115px;
}

@media only screen and (max-width:1024px) {

    .mywishlist_laptop_division {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media only screen and (max-width:768px) {

    .mywishlist_laptop_division {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media only screen and (max-width:576px) {

    .mywishlist_laptop_division {
        grid-template-columns: repeat(1, 1fr);
    }

}

.add_to_cart_btn{
    width: max-content;
}