.carousal_main_div {
    position: relative;
}

.div_2 {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 1;
    width: 99%;
    padding: 10px;
    cursor: pointer;

}

.image_div {
    width: 99%;
    border-radius: 0px;
}

.blog_main_title {
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.blog_main_title2 {
    color: white;
    font-size: 14px;
}

.paragraph_blog {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog_div_2{
    border-top: 5px solid pink;
    border-radius: 5px;
}

.blog_div_3{
    padding: 10px;
}

.image_div_2{
    height: 50px;
    width: 50px;
}