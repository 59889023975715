.Adminheader_main_div {
  background-color: var(--ecom-admin-font-color);
  display: flex;
  align-items: center;
  /* margin-top: 80px; */
  justify-content: end;
  width: 100%;
  height: 60px;
}

.adminheader_logo_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.adminheader_logo {
  height: 100%;
}

.adminlheader_logomain_div {
  width: 56%;
  display: flex;
  justify-content: start;
}

.adminheader_bell_main_div {
  display: flex;
  justify-content: end;
  /* width: 43%; */
}

.doctor_header_notification_title {
  font-size: 25px !important;
  color: var(--ecom-admin-font-color) !important;
}

.notification_button {
  display: flex;
  justify-content: space-between;
}

.admin_notification_item_name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.notification_main2 {
  display: flex;
  position: fixed;
  z-index: 99;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 320px;
  padding: 0px 10px;
}

.notification_sidebar {
  height: 100vh;
  width: 360px;
  background-color: var(--ecom-admin-secondary-color);
  position: fixed;
  right: -100%;
  transition: all ease 0.5s;
  cursor: pointer;
  z-index: 100;
  top: 0;

}

.notificitaion_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgb(148, 148, 148);
  padding: 2px;
}

.text_notification {
  color: var(--ecom-admin-second-secondary-color);
}

.icon_div {
  background-color: #d9d9d9;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_div i {
  padding-right: 0px;
}