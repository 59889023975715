.promocode_main_division {
    /* width: 80%; */
}

.promocode_main_sub_division {
    margin: 30px 20px;
}

.promocode_title {
    align-self: center !important;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 22px;
    color: var(--ecom-admin-font-color) !important;
}

.promocode_addnew_button {
    width: 100px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.promocode_addnew_button:focus {
    box-shadow: none;
}

.promocode_addnew_button:hover {
    color: var(--ecom-admin-secondary-color);
}



.bgColorRed {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    text-transform: capitalize;
}

.promocode_search_feild {
    width: 250px;
    border: 1px solid #b7b7b7;
    padding: 0px 10px;
    height: 40px;
    border-radius: 5px;
}

.promocode_search_feild:focus {
    border: 1px solid var(--ecom-admin-second-secondary-color);
    box-shadow: none;
}

.promocode_search_button {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
    padding: 0px 10px;
    height: 40px;
}

.promocode_search_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.promocode_search_button:focus {
    box-shadow: none;
}

.promocode_bulkaction_dropdown {
    background-color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-font-color);
    color: var(--ecom-admin-font-color);
    width: 200px;
    text-transform: capitalize;
}

.promocode_bulkaction_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.promocode_bulkaction_dropdown_menu {
    color: var(--ecom-admin-font-color);

}

.promocode_bulkaction_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.promocode_bulkaction_apply_button {
    margin: 0px 5px;
    padding: 0px 25px;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}

.promocode_bulkaction_apply_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.promocode_bulkaction_apply_button:focus {
    box-shadow: none;
}



.promocode_filterbystockstatus_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--black-background-color);
    width: 200px;
}

.promocode_filterbystockstatus_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.promocode_filterbystockstatus_dropdown_menu {
    text-transform: capitalize;
    color: var(--ecom-admin-font-color);
}

.promocode_filterbystockstatus_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.promocode_bulkaction_filter_button {
    margin: 0px 5px;
    padding: 0px 25px;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}

.promocode_bulkaction_filter_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.promocode_bulkaction_filter_button:focus {
    box-shadow: none;
}

.promocode_apds_division {
    display: flex;
}

.active_promocode_apds {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
}

.active_promocode_apds span {
    padding-left: 5px;
}

.promocode_apds {
    color: var(--ecom-admin-font-color);
    font-size: 16px;
    align-self: center;
    margin-left: 5px;
}

.promocode_apds span {
    color: var(--black-background-color);
    margin: 0px 5px;
}


/* Checkbox color change CSS Start */
.ant-checkbox-inner {
    border: 1px solid var(--ecom-admin-font-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #483a15 !important;
    border-color: #483a15 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #483a15 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #483a15 !important;
}

/* Checkbox color change CSS End */
/* .ant-pagination-item-active {
    font-weight: 500;
    color: white;
    background: #483a15 !important;
    border-color: #483a15 !important;
}

.ant-pagination-item {
    border-color: #483a15 !important;
}
 */


.ant-table-tbody>tr>td {
    border-bottom: 15px solid #4F326710;
    height: 80px;
    padding: 5px;
}


.ant-table-thead>tr>th {
    border-bottom: 15px solid #4F326710;
}