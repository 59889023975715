.addpromocode_main_division {
    /* width: 80%; */
}

.addpromocode_main_sub_division {
    margin: 30px 20px;
}

.addpromocode_add_new_product_title {
    font-size: 25px;
    margin-bottom: 10px;
    color: var(--ecom-admin-font-color);

}

.addpromocode_sub_main_division {
    display: flex;
}

.addpromocode_add_new_product_division {
    width: 70%;
}

.addpromocode_formcontrol {
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
    padding: 3px 10px;
    background: var(--back-color) !important;
    border-color: var(--ecom-admin-font-color);
    margin-bottom: 20px;
    height: 40px;
}

.addpromocode_formcontrol:focus-visible {
    border: 1px solid var(--ecom-admin-border) !important;
    color: var(--ecom-admin-second-color-secondary);
}

.addpromocode_permalink_breadcrums {
    display: flex;
    margin: 10px 0px 15px 0px;
}

.addpromocode_description_form {
    border-color: var(--ecom-admin-font-color);
    margin-bottom: 30px;
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
    padding: 3px 10px;
    background: var(--back-color) !important;
}

.addpromocode_description_form:focus-visible {
    border: 1px solid var(--ecom-admin-border) !important;
    color: var(--ecom-admin-second-color-secondary);
}

.addpromocode_generate_code_btn {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    border: none;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    color: var(--ecom-admin-font-color);
}

/* start product data section */


.addpromocode_product_data_main_division {
    border: 1px solid var(--ecom-admin-border);
    margin-bottom: 20px;
    border-color: var(--ecom-admin-font-color);
    border-radius: 10px;
    background: var(--back-color);
}

.addpromocode_product_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-light-border);
}

.addpromocode_product_data_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-font-color);
}

.addpromocode_category_list_details {
    display: flex;
}

.addpromocode_category_list1 {
    display: flex;
    margin-bottom: 10px;
    padding: 20px 0 0 10px;
}

.addpromocode_category_list {
    width: 20%;
    border-right: 1px solid var(--ecom-admin-light-border);
}

.addpromocode_category_active {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
    border-bottom: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
}

.addpromocode_category_active1 {
    width: 20%;
    height: max-content;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 3px solid var(--ecom-admin-font-color);
    color: var(--ecom-admin-second-secondary-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.addpromocode_category {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    border-bottom: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
}

.addpromocode_category1 {
    height: max-content;
    font-size: 15px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    cursor: pointer;
    width: 20%;
    display: flex;
    justify-content: center;
}

.addpromocode_category1:focus {
    border-bottom: 3px solid var(--ecom-admin-font-color);
}

.addpromocode_category:focus {
    border-bottom: 1px solid var(--ecom-admin-font-color);
}


/* Addcoupon general section */

.addpromocode_coupondata_main_division {
    width: 80%;
    padding: 10px;
}

.addpromocode_coupondata_division {
    display: flex;
    align-items: start;
    margin-bottom: 10px;
}

.addpromocode_coupondata_left_side {
    width: 25%;
}

.addpromocode_coupondata_right_side {
    width: 75%;
}

.addpromocode_discounttype_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-font-color);
    color: var(--ecom-admin-font-color);
    width: 50%;
    text-transform: capitalize;
}

.addpromocode_discounttype_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.addpromocode_discounttype_dropdown:hover {
    color: var(--ecom-admin-font-color);
}

.addpromocode_discounttype_dropdown_menu_active {
    text-transform: capitalize;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}

.addpromocode_discounttype_dropdown_menu_active:hover {
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}

.addpromocode_discounttype_dropdown_menu {
    text-transform: capitalize;
    color: var(--ecom-admin-font-color);
}

.addpromocode_discounttype_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.addpromocode_couponamount_text_field {
    width: 50%;
    padding: 8px 10px;
    font-size: 14px;
}

.addpromocode_couponamount_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.addpromocode_allow_free_shipping_chechbox {
    width: 50px;
}

.addpromocode_allow_free_shipping_chechbox_label span {
    color: var(--ecom-admin-font-color);
}

/* star usage rescrisation */

.addpromocode_usage_main_division {
    width: 75%;
}

.addpromocode_usage_division {
    padding: 10px;
}

.addpromocode_usage_tab_main_division {
    display: flex;
    padding: 10px;
}

.addpromocode_usage_products_title {
    width: 10%;
    font-size: 15px;
    font-weight: 600;
}

.addpromocode_usage_exclude_products_title {
    font-size: 15px;
    font-weight: 600;
}

.addpromocode_usage_sub_main_division {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.addpromocode_minimum_spend_title {
    width: 20%;
    display: flex;
    /* align-items: center; */
}

.addpromocode_minimum_spend_title_text_field {
    width: 50%;
    padding: 8px 10px;
    font-size: 14px;
}

.addpromocode_minimum_spend_title_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.addpromocode_minimum_spend_title_text_field1 {
    width: 100%;
    padding: 8px 10px;
    font-size: 14px;
}

.addpromocode_minimum_spend_title_text_field1:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.addpromocode_searchfield_division {
    width: 50%;
}

.addpromocode_suggestions_box_division {
    border: 1px solid var(--ecom-admin-second-color);
    border-radius: 5px;
    padding: 10px;
}

.addpromocode_suggestions_list {
    display: flex;
    width: 100%;
}

.addpromocode_close_icon_division {
    width: 10%;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}

.addpromocode_suggestions_field {
    margin-bottom: 5px;
    cursor: pointer;
    width: 90%;
}

.addpromocode_question_icon {
    display: flex;
    align-items: center;
    width: 30%;
    margin-left: 10px;
}

.addpromocode_usage_sub_main_division1 {
    display: flex;
    width: 100%;
    padding: 10px;
}

.addpromocode_usage_checkbox_sub_main_division {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.addpromocode_check_box_division {
    display: flex;
    align-items: center;
}

.addpromocode_usage_sub_main_division2 {
    display: flex;
    width: 100%;
}

.addpromocode_usage_bubbles_division {
    border: 1px solid var(--ecom-admin-second-color);
    border-radius: 15px;
    padding: 5px;
    display: flex;
    width: max-content;
    cursor: pointer;
}

.addpromocode_close_bubble_icon {
    font-size: 17px;
    display: flex;
    align-items: center;
}

.addpromocode_bubble_text {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

/* star usage limit */

.addpromocode_limit_main_division {
    width: 75%;
    padding: 10px;
}

.addpromocode_limit_sub_main_division {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.addpromocode_limit_minimum_spend_title {
    width: 20%;
    display: flex;
    align-items: center;
}

.addpromocode_limit_minimum_spend_title_text_field {
    width: 50%;
    height: 30px;
    font-size: 14px;
}

.addpromocode_limit_minimum_spend_title_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.addpromocode_limit_question_icon {
    display: flex;
    align-items: center;
    width: 30%;
    margin-left: 10px;
}

.addpromocode_limit_sub_main_division1 {
    display: flex;
    width: 100%;
    padding: 10px;
}



/* start publish section */


.addpromocode_main_Social_division {
    width: 30%;
    margin: 0 20px 0 20px;

}

.addpromocode_main_publish_division {
    border: 1px solid var(--ecom-admin-font-color);
    margin-bottom: 20px;
    border-radius: 10px;
}

.addpromocode_publish_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border);
}

.addpromocode_publish_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-second-secondary-color);
}

.addpromocode_publish_details_field {
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border);
}

.addpromocode_publish_field_button {
    display: flex;
}

.addpromocode-btn-save-draft {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    width: 28%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
}

.addpromocode-btn-preview {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
    margin-left: 140px;
}

.addpromocode_edit_status {
    display: flex;
    margin-top: 10px;
}

.addpromocode_status_icon {
    color: #7c7c7d;
    padding-right: 5px;
}

.addpromocode_status {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.addpromocode_draft {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.addpromocode_edit {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: var(--ecom-admin-font-color);
}

.addpromocode_last_division_new_draft {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    margin-left: 10px;
}

.addpromocode_new_draft {
    color: var(--ecom-admin-second-secondary-color);
    padding-bottom: 5px;
}

.addpromocode_move_button {
    display: flex;
}

.addpromocode_move_trash {
    color: var(--ecom-admin-font-color);
}

.addpromocode-btn-publish {
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
    border-radius: 5px;
    height: 30px;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
}

/* end publish section */



@media only screen and (max-width:768px) {

    .addpromocode_category_list {
        width: 30%;
    }

    .addpromocode_coupondata_main_division {
        width: 70%;
    }

    .addpromocode_discounttype_dropdown {
        width: 70%;
    }

    .addpromocode_category_active1 {
        width: 40% !important;
    }

    .addpromocode_category1 {
        width: 40% !important;
    }

    .addpromocode_limit_minimum_spend_title {
        width: 35%;
    }

    .addpromocode_limit_question_icon {
        width: 5%;
    }

    .addpromocode_limit_minimum_spend_title_text_field {
        width: 60%;
    }

    .addpromocode-btn-publish {
        margin: 0px;
        width: 100%;
    }

}


@media only screen and (max-width:1024px) {

    .addpromocode_category_active1 {
        width: 30%;
    }

    .addpromocode_category1 {
        width: 30%;
    }
}