.header_main_division {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--secondary-color);
}

.header_division {
    padding: 10px 20px;
}

.header_logo {
    height: 74px;
    padding: 5px;
    cursor: pointer;
}


.header_search_bar_main_division {
    width: 50%;
}

.header_search_bar_main_division_mobile {
    display: flex;
    align-items: center;
    display: none;
}

.header_search_bar {
    display: flex;
    background-color: var(--secondary-color);
    border-radius: 30px;
    padding: 12px;
    border: 1px solid var(--border-color);
    position: relative;
    align-items: center;
    justify-content: flex-end;
}

.header_search_bar:focus-within {
    border: 2px solid var(--border-color);
}

.header_search_feild {
    border: none;
    color: var(--arihant-backgroung-color);
    padding: 0px;
}

.header_search_feild:focus {
    background-color: var(--secondary-color) !important;
    color: var(--arihant-backgroung-color) !important;
}

.header_search_icon {
    align-self: center;
    cursor: pointer;
}

.searchbar_main_division {
    background-color: var(--secondary-color);
    height: max-content;
    padding: 0px 12px;
    display: block;
    position: absolute;
    border-radius: 5px;
    height: auto;
    overflow-y: scroll;
    border: 1px solid black;
    width: 100%;
    left: 0;
    top: 50px;
}

.searchbar_main_division::-webkit-scrollbar {
    display: none;
}

.serachbar_sub_division {
    display: flex;
    margin-bottom: 10px;
}

.cart_icon {
    position: absolute;
    right: 0px;
    top: -5px;
    z-index: 1;
    background: linear-gradient(to right, #de57e5 0%, #8863fb 100%);
    height: 15px;
    width: 15px;
    color: var(--secondary-color);
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.searchbar_first_main_division {
    width: 100%;
}

.searchbar_serach_list {
    color: var(--arihant-backgroung-color);
    text-align: start;
    cursor: pointer;
}

.searchbar_close_icon {
    color: var(--arihant-backgroung-color);
    cursor: pointer;
}

.header_cart_icon {
    text-align: center;
    cursor: pointer;
    margin-right: 7px;
}


.svg_hover:hover {
    scale: 1.1;
    color: var(--theme-default) !important;
}

.cart_length {
    position: absolute;
    height: 15px;
    width: 15px;
    background: linear-gradient(to right, #de57e5 0%, #8863fb 100%);
    font-size: 0.7rem;
    top: -5px;
    text-align: center;
    color: var(--secondary-color);
    border-radius: 5px;
    display: inline-block;
    left: 16px;

}

.header_user_icon_division {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.header_heart_icon {
    cursor: pointer;
}

.css-prty3w-MuiBadge-root .MuiBadge-badge {
    border: none !important;
    padding: 0 4px;
    background: var(--theme-default);
    border-radius: 48%;
    height: 17px;
}




#full-scr {
    height: 100vh;
    width: 100%;
    background: white;
    position: fixed;
    z-index: 99;
    left: -100%;
    transition: all ease 0.5s;
}

.header_bar_icon {
    display: none;
}


.new_ul_list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.new_ul_list li {
    list-style: none;
    cursor: pointer;
    color: rgb(79, 50, 103);
    font-size: 16px;
    border-bottom: 1px solid var(--secondary-color);
}

.new_ul_list li:hover {
    color: var(--theme-default);
    border-bottom: 1px solid var(--theme-default);
}

.new_ul_list_mobile {
    padding-left: 0px;
}

.new_ul_list_mobile li {
    list-style: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-bottom: .5px solid #b9b9b9;
    padding: 0.5rem 1rem;
}

.main_pankaj_update_header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


























































.nprofile-dropdown {
    position: relative;
    display: inline-block;
}

.nprofile-icon {
    cursor: pointer;
}

.nprofile-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.ndropdown-box {
    position: absolute;
    top: 50px;
    /* Adjust as needed */
    right: 0;
    width: 150px;
    /* Adjust as needed */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    z-index: 1000;
}

.ndropdown-box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ndropdown-box li {
    padding: 10px;
    cursor: pointer;
}

.ndropdown-box li:hover {
    background-color: #f0f0f0;
}





























.back_title{
    text-transform: uppercase;
    margin-right: 20px;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
}



/* new category list start */

.cat_main {
    position: relative;
    width: 50%;
}

.category-item {
    position: relative;
}

.subcategory-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 0px 5px 0px black;
    display: none;
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    width: 135px;
}

.category-item:hover .subcategory-dropdown {
    display: block;
}

.subcategorylist_name {
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    cursor: pointer;
}

.search_icon{
    margin-right: 20px;
    cursor: pointer;
}

/* new category list END */

@media only screen and (max-width:768px) {

    .cat_main,
    .search_icon {
        display: none;
    }

    .main_pankaj_update_header {
        display: flex;
        justify-content: space-between;
    }

    .header_search_bar_main_division {
        display: none;
    }

    .header_search_bar_main_division_mobile {
        display: block;
    }

    .header_division {
        padding: 10px;
    }

    .new_ul_list {
        display: none !important;
    }

    .header_bar_icon {
        display: block;
    }

    .bottom_item_bar {
        display: none !important;
    }

    .header_small_titlee {
        display: none;
    }

    .header_user_icon_division,
    .header_cart_icon {
        margin-right: 5px;
    }

}


/* sub cat accordian start */

.css-o4b71y-MuiAccordionSummary-content{
    align-items: center;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin: 0px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin: 0px !important;
}
.css-15v22id-MuiAccordionDetails-root{
    padding: 8px 60px !important;
}
/* sub cat accordian end */