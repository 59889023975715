.dashboard_first_div {
    justify-content: center;
}

.first_small_div {
    padding: 20px;
    background-color: #7e7660;
    color: var(--ecom-admin-secondary-color);
    display: flex;
    justify-content: space-between;
    background-image: url(../../../../images/back.png);
    background-size: cover;
    width: 300px;
}

.first_small_div h4 {
    color: var(--ecom-admin-secondary-color);
}

.icon_back {
    background-color: #ffffff1a;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_back i {
    padding-right: 0px !important;
}

.chart_one {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 10px;
    padding: 10px;
}

.revenuechart_main_div {
    width: 100%;
    height: 500px;
}