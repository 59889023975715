.myorder_path {
    color: var(--black-background-color);
    font-size: 14px;
    font-weight: 200;
}


.myorder_path_icon {
    color: var(--second-secondary);
    margin-left: 15px;
    font-size: 14px;
}

.myorder_path_details {
    color: black;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}



.order_title_main {
    color: black;
    font-size: 25px;
    font-weight: 600;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--secondary-color);
    background-color: var(--theme-default);
}

.nav-link {
    color: var(--secondary-color);
}

.nav-link:focus,
.nav-link {
    color: var(--theme-default);
}



.css-h2cmlr {
    color: var(--secondary-color) !important;
}



.cjaMGq {
    width: 80% !important;
    height: 10% !important;
    font-size: 1em;
    align-items: center;
}

.jDoOSc {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: var(--theme-default) !important;
    color: var(--secondary-color);
    -webkit-animation: iAjNNh 1s ease;
    animation: iAjNNh 1s ease;
}

.zURZJ:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 2.375rem;
    background: var(--theme-default) !important;
    -webkit-animation: gHpPbo 1s ease;
    animation: gHpPbo 1s ease;
}

.fNnNlK {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 0.8em;
    color: var(--secondary-color) !important;
}

.NWEot {
    margin: 0;
    margin-top: 0.75rem;
    font-weight: bold;
    color: var(--secondary-color) !important;
}

.hYqbrA {
    display: inline-block;
    margin: 0;
    margin-top: 10px !important;
    padding: 0.5rem 0.55rem !important;
    border-radius: 2.375rem;
    font-size: 0.8em;
    background: var(--theme-default) !important;
    color: var(--secondary-color) !important;
}

.deDWWh {
    margin: 1 !important;
    /* margin-top: 0.95rem !important; */
    font-size: 14px !important;
    color: var(--secondary-color) !important;
    margin-right: 5px !important;
}

.daEmb {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100px !important;
    margin: space-between !important;
}

.jGWOeb {
    margin: 0;
    margin-top: 0.95rem !important;
    font-size: 0.8em;
    color: var(--secondary-color) !important;
}

.dXQRRE {
    margin: 0;
    margin-top: 0.75rem;
    font-weight: bold;
    color: var(--secondary-color) !important;
}

.fJkvMo {
    display: inline-block;
    margin: 0;
    margin-top: 0.75rem;
    padding: 0.4rem 0.75rem;
    border-radius: 2.375rem;
    font-size: 0.8em;
    background: var(--theme-default) !important;
    color: var(--secondary-color) !important;
}

.daEmb {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    margin-top: 1em !important;
}

.ffkMDc {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 0.9em;
    color: var(--secondary-color) !important;
}

.ldDAHN {
    margin: 0;
    margin-top: 0.75rem;
    font-weight: bold;
    color: var(--secondary-color) !important;
}

.jZlNxf {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 0.8em;
    color: var(--secondary-color) !important;
}

.iTJWMo {
    display: inline-block;
    margin: 0;
    margin-top: 0.75rem;
    padding: 0.4rem 0.75rem;
    border-radius: 2.375rem;
    font-size: 0.8em;
    background: var(--secondary-color);
    color: var(--theme-default) !important;
}

.fSaqDQ {
    margin: 0;
    margin-top: 0.75rem;
    font-weight: bold;
    color: var(--secondary-color) !important;
}

.ipqqxO {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 0.8em;
    color: var(--secondary-color) !important;
}

.dKbLDJ {
    display: inline-block;
    margin: 0;
    margin-top: 0.75rem;
    padding: 0.4rem 0.75rem;
    border-radius: 2.375rem;
    font-size: 0.8em;
    background-color: var(--secondary-color) !important;
    color: var(--theme-default) !important;
}

.fcETaI {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: var(--theme-default) !important;
    color: var(--secondary-color);
    -webkit-animation: iAjNNh 1s ease;
    animation: iAjNNh 1s ease;
}

.hMocwd:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 2.375rem;
    background: var(--theme-default) !important;
    -webkit-animation: gHpPbo 1s ease;
    animation: gHpPbo 1s ease;
}

.hMocwd {
    position: relative;
    width: 100%;
    height: 0.3em;
    border-radius: 2.375rem;
    margin: 0 1em;
    background: var(--secondary-color) !important;
}

.fjCUmV {
    position: relative;
    width: 100%;
    height: 0.3em;
    border-radius: 2.375rem;
    margin: 0 1em;
    background: var(--secondary-color) !important;
}

.gAEohL {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: var(--secondary-color) !important;
    color: var(--black-background-color) !important;
}

.bjSYwh {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 0.8em;
    color: var(--secondary-color) !important;
}

.jzPabL {
    width: 100%;
    height: 100%;
    font-size: 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px !important;
}


.css-4ff9q7.Mui-active {
    color: var(--secondary-color) !important;
}

.css-10psl7k {
    fill: black;
}

.css-4ff9q7.Mui-completed {
    color: var(--theme-default) !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: var(--secondary-color) !important;
}

.css-1hv8oq8-MuiStepLabel-label {
    color: var(--secondary-color);
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
    color: var(--secondary-color) !important;
}

.vertical_stepper_date {
    color: var(--secondary-color) !important;
}

.vertical_stepper_date_jewellery {
    color: var(--secondary-color) !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--theme-default) !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--arihant-color-border-second) !important;
}



/* khushbu start */
.order_image_page {
    width: 30%;
    border: 10px solid #d9d9d950;
    border-radius: 10px;
}

.product_to_Order_page {
    display: flex;
    padding: 20px;
    border-radius: 10px;
}

.invoice_type_order_page {
    padding: 10px;
    color: white;
    background: var(--btn-back);
    border-radius: 12px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
}

.right_image_product {
    margin-left: 20px;
}

.name_prdct_order_page,
.price_prdct_order {
    font-size: 18px;
}

.order_page_main {
    display: flex;
    background-color: #fafafa;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    height: max-content;
    padding: 10px;
    filter: drop-shadow(0px 0px 10px #00000025);

}

.delieverd_status_order_page {
    font-size: 12px;
    margin-top: 10px;
}

.rat_order {
    color: goldenrod;
}

.Rating_order_page {
    font-size: 18px;
    margin-top: 20px;
}

.refund_type_order_page {
    padding: 8px;
    color: white;
    background: var(--theme-default);
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    border-radius: 0px;
}

.des_prder_name {
    margin-left: 20px;
    margin-top: 20px;
}


.delivery_main_order_page_ {
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
}

.visit_again_message_order {
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
}


.order_placed_order_page_down {
    border-radius: 10px;
    font-size: 17px;
    display: flex;
}



.order_info_order_page_main {
    display: flex;
}


.one_line_order_page {
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;

    margin-top: 20px;
    width: 20%;
}

@media only screen and (max-width:576px) {
    .order_page_main {
        display: block;
    }

    .order_image_page {
        width: 50%;
    }

    .order_info_order_page_main {
        display: block;
    }


    .one_line_order_page {
        margin-top: 20px;
    }

}

@media only screen and (max-width:768px) {
    .product_to_Order_page {
        display: block;
    }

    .order_image_page {
        width: 70%;
    }
}


.myorder_product_informations {
    width: 80%;
    height: max-content;
}



.myorder_jewellery_iamge {
    padding: 10px;
    display: flex;
    justify-content: start;
}



.myorderimg {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.myorder_title {
    padding: 0px 10px 10px 10px;
}



.myorder_order_id_title {
    color: var(--theme-default);
    font-weight: 500;
    font-size: 15px;
}

.myorder_order_number {
    color: black;
    font-weight: 300;
    margin-left: 5px;
    font-size: 15px;
}

.myorder_jewellery_name {
    width: max-content;
    color: black;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .myorder_jewellery_name {
        font-size: 16px;
        font-weight: 600;
    }
}

.myorder_price {
    color: black;
    font-size: 22px;
    font-weight: 500;
}

@media only screen and (min-width:320px) and (max-width:1024px) {
    .myorder_price {
        font-size: 18px;
    }
}

.myorder_download_invoice {
    color: white;
    margin-top: 20px;
    display: flex;
    cursor: pointer;
    background: var(--theme-default);
    padding: 10px;
    border-radius: 5px;
    width: max-content;
    border-radius: 0px;
}



.myorder_invoice_icon {
    display: flex;
    align-items: center;
    color: white !important;
}

.myorder_delivery_address_title {
    color: var(--theme-default);
    font-weight: 600;
    width: max-content;
    font-size: 17px;
    margin-top: 10px;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .myorder_delivery_address_title {
        font-size: 14px;
        margin-top: 3px;
    }
}

.myorder_order_person_name {
    color: black;
    font-size: 15px;
}

.myorder_delivery_address {
    color: black;
    width: max-content;
    font-size: 14px;
    word-break: break-all;
}

.myorder_mumber_division {
    display: flex;
    align-items: center;
    width: max-content;
}

.myorder_number_title {
    color: black;
    font-size: 15px;
}

@media only screen and (max-width:320px) {
    .myorder_number_title {
        font-size: 12px;
    }
}

.myorder_number {
    color: black;
    font-size: 15px;
    font-weight: 300;
}

@media only screen and (max-width:320px) {
    .myorder_number {
        font-size: 12px;
    }
}

.myorder_note_from_pankaj_jewellers_division {
    margin-top: 10px;
}

@media only screen and (max-width:425px) {
    .myorder_note_from_pankaj_jewellers_division {
        display: block;
    }
}

.myorder_note_pankaj_jewellers {
    color: var(--theme-default);
    font-size: 15px;
    width: max-content;
    text-transform: capitalize;
    font-weight: 700;
}

.myorder_note_from_pankaj_jewellers {
    color: black;
    font-size: 17px;
    width: max-content;
    text-transform: capitalize;
    font-weight: 400;
    margin-left: 5px;
}

@media only screen and (max-width:425px) {
    .myorder_note_from_pankaj_jewellers {
        margin-left: 0px;
    }
}

.regard_pankaj_order_side {
    font-size: 15px !important;
    font-weight: 600;
    color: var(--theme-default);
    width: max-content;
}



.review_popup_post_review_button_division{
    display: flex;
    justify-content: center;
}
.review_popup_post_review_button {
    width: max-content;
    font-size: 18px;
    font-weight: 700;
    padding: 7px 70px;
    color: var(--secondary-color);
    border-radius: 10px;
    background-color: var(--theme-default);
    margin: 20px 0px;
    border: none; 
}