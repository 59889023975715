.address_breadcrums {
    display: flex;
}

.address_path {
    font-size: 14px;
    font-weight: 200;
}

@media only screen and (max-width:425px) {
    .address_path {
        margin-left: 0px;
    }
}

@media only screen and (min-width:768px)and (max-width:1023px) {
    .address_path {
        margin-left: -4px;
    }
}

@media only screen and (min-width:1024px) and (max-width:1439px) {
    .address_path {
        margin-left: -10px;
    }
}

.address_path_icon {
    color: var(--second-secondary);
    margin-left: 15px;
    font-size: 14px;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .address_path_icon {
        margin-left: 5px;
    }
}

.address_path_details {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 200;
    cursor: pointer;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .address_path_details {
        margin-left: 0px;
    }
}

.address_path_manage_details {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}


.address_checkbox_button {
    border-bottom: 1px solid #C7C6C6;
}

.address_extra_division {
    padding-top: 10px;
}

.address_second_extra_division {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (min-width:320px) and (max-width:374px) {
    .address_second_extra_division {
        padding: 0%;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width:375px) and (max-width:424px) {
    .address_second_extra_division {
        padding: 0%;
        display: contents;
        /* justify-content: center; */
    }
}

@media only screen and (min-width:425px) and (max-width:767px) {
    .address_second_extra_division {
        padding: 0%;
        display: contents;
        /* justify-content: center;  */
    }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .address_second_extra_division {
        padding: 0%;
        display: contents;
    }
}

@media only screen and (min-width:1024px) and (max-width:1430px) {
    .address_second_extra_division {
        padding: 0%;
        display: contents;
    }
}


@media only screen and (min-width:320px) and (max-width:374px) {
    .address_page_form {
        padding: 0px;
        margin: 0px;
        display: block;
    }
}

@media only screen and (min-width:375px) and (max-width:424px) {
    .address_page_form {
        padding: 50px;
        display: contents;
    }
}

@media only screen and (min-width:425px) and (max-width:767px) {
    .address_page_form {
        padding: 50px;
        display: contents;
    }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .address_page_form {
        padding: 50px;
        display: contents;
    }
}

@media only screen and (min-width:1024px) and (max-width:1430px) {
    .address_page_form {
        padding-left: 50px;
        /* padding-right: 50px; */
        display: contents;
    }
}

.address_page_title {
    color: var(--theme-default);
    text-transform: uppercase;
    font-size: 20px;
    border-bottom: 1px solid #C7C6C6;
    font-weight: 600;
    margin-bottom: 20px;
}

.address_form_main_division {
    margin-right: calc(var(--bs-gutter-x) * -.0) !important;
    margin-left: calc(var(--bs-gutter-x) * -.0) !important;
}

@media only screen and (max-width:425px) {
    .address_form_row {
        padding: 0px 0px;
    }
}

.address-form-label {
    color: black;
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 5px;
}

.address_type {
    color: black;
    font-size: 15px;
    font-weight: 500;
}

.addressformControl {
    height: 3.2rem;
    width: 100%;
    font-size: 0.9rem;
    padding: 1.2rem 2.0rem;
    font-weight: 400;
    margin-bottom: 5px;
    box-shadow: none !important;
}

.addressformControl:focus {
    border: 1px solid var(--theme-default);
}

.address-add-right {
    position: relative;
    /* z-index: -1; */
}

.address-input-group-addon {
    position: absolute;
    top: 50%;
    left: 1.9rem;
    -webkit-transform: translateY(-46%);
    transform: translateY(-48%);
    font-size: 0.9rem;
}

@media only screen and (max-width:425px) {
    .address-input-group-addon {
        margin-left: 0px;
        left: 0.5rem;
    }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .address-input-group-addon {
        margin-left: 0px;
    }
}

.addressnumberformControl {
    height: 3.2rem;
    width: 100%;
    font-size: 0.9rem;
    padding: 1.2rem 4.0rem;
    font-weight: 400;
    box-shadow: none;
    z-index: -1;
}

.addressnumberformControl:focus {
    border: 1px solid rgb(136, 99, 251);
}


.address_error {
    color: var(--theme-default);
    font-size: 15px;
    padding-top: 5px;
    font-weight: 500;
}

.address_field {
    margin-top: 0px;
}

@media only screen and (max-width:425px) {
    .address_field {
        padding: 0px;
    }
}

.address_radio_button {
    display: flex;
    color: var(--secondary-color);
    margin-top: 10px;
}

.address_radio_button_form_check {
    display: flex;
    margin-right: 0rem !important;
    margin-bottom: 0px !important;
}

@media only screen and (max-width:425px) {
    .address_radio_button {
        padding-left: 0px !important;
    }
}

.form-check-input {
    border: 1px solid var(--secondary-color);
    width: 20px;
    height: 20px;
    margin-top: 0.2em;
    background-color: var(--black-background-color);
}

@media only screen and (min-width:320px) and (max-width:374px) {
    .form-check-input {
        margin-left: 5px;
    }
}


.form-check-input:focus-visible {
    border: transparent;
}

.address_breadcrums-form-check {
    display: flex;
}

.form-check-label {
    margin-right: 50px;
}

@media only screen and (max-width:425px) {
    .form-check-label {
        margin-right: 10px !important;
    }
}

.address-form-check-label {
    margin-left: 5px;
    font-size: 15px;
}

.address-btn-save {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    float: right;
    height: 38px;
    width: 219px;
    font-size: 17px;
    font-weight: 700;
    border: none;
    align-items: center;
}