.ant-carousel .slick-dots-bottom {
    bottom: -10px;
    z-index: -1;
}


.gift_of_choice {
    width: 100%;
}

.hjgOMH {
    background-color: var(--theme-default) !important;
    box-shadow: 0 0 1px 3px var(--theme-default) !important;
}

.kqbBYV {
    color: var(--secondary-color) !important;
    background-color: var(--theme-default) !important;
}

.kpEZiB {
    background-color: var(--theme-default) !important;
    box-shadow: 0 0 1px 3px var(--theme-default) !important;
}

.kTBEUG {
    color: var(--secondary-color) !important;
    background-color: var(--theme-default) !important;
}

.sc-hBxehG .eNkvks .rec .rec-item-wrapper {
    width: 310px !important;
}

.css-1fwwzvr {
    color: var(--theme-default) !important;
}

.css-1xh6k8t {
    color: var(--theme-default) !important;
}


.homepage_category_section {
    margin: 0px 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.homepage_category_image {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 200px;
    object-fit: contain;
    border-radius: 100px;
    border: 2px solid #EBB6BA
}

.homepage_category_image:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

@media only screen and (max-width:1024px) {
    .homepage_category_image {
        height: 135px;
        width: 135px;
    }
}

@media only screen and (max-width:425px) {
    .homepage_category_image {
        height: 120px;
        width: 120px;
    }
}

@media only screen and (max-width:375px) {
    .homepage_category_image {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (max-width:320px) {
    .homepage_category_image {
        height: 80px;
        width: 80px;
    }
}


.discounted_price {
    font-size: 1.3rem;
}

.homepage_category_title {
    color: black;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
    text-transform: capitalize;
    margin-top: 10px;
}

@media only screen and (max-width:1024px) {
    .homepage_category_title {
        width: 135px;
    }
}

@media only screen and (max-width:425px) {
    .homepage_category_title {
        font-size: 15px;
        width: 120px;
    }
}

@media only screen and (max-width:375px) {
    .homepage_category_title {
        width: 100px;
    }
}

@media only screen and (max-width:320px) {
    .homepage_category_title {
        width: 80px;
    }
}

.homepage_title_name {
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
}


@media only screen and (min-width:320px)and (max-width:425px) {
    .chvrzP {
        min-width: none !important;
    }
}


.homepage_product_card {
    position: relative;
    margin: 0px 10px;
}


.product-storage {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.1 !important;
}

.homepage_product_card .card {
    position: relative;
    width: 255px;
    height: 370px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);

}

.homepage_product_card .card .imgBx {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    transition: .5s;
}

.homepage_product_card .card:hover .imgBx {
    top: 0%;
    transform: translateY(-20%);
}

.homepage_product_card .card .imgBx img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.homepage_product_card .card .contentBx {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 140px;
    text-align: center;
    transition: 1s;
}

.homepage_product_card .card:hover .contentBx {
    height: 170px;
}

.homepage_product_card .card .contentBx h2 {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    color: var(--black-background-color) !important;
    line-height: 1.1;
}

.homepage_product_card .card .contentBx .size,
.homepage_product_card .card .contentBx .color {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.homepage_product_card .card:hover .contentBx .size {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
}

.homepage_product_card .card:hover .contentBx .color {
    opacity: 1;
    visibility: visible;
    transition-delay: .6s;
}

.homepage_product_card .card .contentBx .size h3,
.homepage_product_card .card .contentBx .color h3 {
    color: var(--black-background-color) !important;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.1;
}

.product-card-origanl-price {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}

.homepage_product_card .card .contentBx .size span {
    width: max-content;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    display: inline-block;
    color: var(--arihant-secondary-color);
    margin: 0 5px;
    transition: .5s;
    color: var(--arihant-secondary-color);
}

.homepage_product_card .card .contentBx .color span {
    width: 20px;
    height: 20px;
    /* background: #ff0; */
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.homepage_product_card .card .contentBx a {
    display: inline-block;
    padding: 7px 20px;
    background: linear-gradient(90deg, #E33272 0%, #F16867 100%);
    border-radius: 4px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 600;
    color: black;
    opacity: 0;
    transform: translateY(50px);
    transition: .2s;
}

.homepage_product_card .card:hover .contentBx a {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .5s;
}

.product-card-wishlist {
    width: 40px;
    height: 40px;
    left: 627px;
    top: 318px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    border-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}



.gold_padents {
    cursor: pointer;
}


/* mobile_view_css_start */
.homepage_product_card_division_mobile {
    border-radius: 10px;
    padding: 20px 10px;
    max-width: 1300px;
    overflow-y: scroll;
    -overflow-style: none !important;
    display: flex;
    align-items: center;
}

@media only screen and (min-width:576px) {
    .homepage_product_card_division_mobile {
        display: none;
    }
}

.homepage_product_card_division_mobile::-webkit-scrollbar {
    display: none;
}

.homepage_product_card_mobile {
    position: relative;
    margin: 0px 10px;
}


.homepage_product_card_mobile .card_mobile .imgBx_mobile {
    width: 100%;
    height: 100%;
}



.product-card-origanl-price {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}


.product-review-division1_mobile {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 20px;
    margin-top: -10px;
}

.posterfirst {
    /* width: 45%; */
    cursor: pointer;
}

.cart_necklace {
    width: 40%;

}

.feature_part {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 0px 8px;
    padding-top: 5px;
    background-color: rgb(222, 127, 222);
    text-align: center;

}

.ratings_part {
    position: absolute;
    bottom: 0;
    left: 0;
    color: black;
    background-color: white;
    border-radius: 8px;
    padding: 5px 10px;
    /* font-family: lato !important; */
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;

}

.product_picture {
    position: relative;
}

.price_of_product {
    font-size: 20px;
    margin: 15px 0px;
}

.name_reference_of_product {
    font-size: 25px;
    /* font-family: lato !important; */
}

.place_order_to_cart button {
    width: 100%;
    font-size: 1.25vw;
    background-color: #ffdde4;
    border-radius: 5px;
    border: none;
    /* font-family: lato !important; */
}

.place_order_to_cart :hover {
    background-color: #E9718B;
}

.cart_product:hover .name_reference_of_product {
    text-decoration: underline;
}

.regular_price {
    font-size: 12px;
    text-decoration: line-through;
    margin-left: 10px;

}

.price_both_discounted_and_regular {
    display: flex;
    align-items: center;
}

.cart_product {
    margin-top: 20px;
    width: 70%;
}




.homepage_card_main {
    padding: 10px;
    cursor: pointer;
}

.homepage_card_top {
    position: relative;
    overflow: hidden;
}

.left {
    position: absolute;
    color: white;
    padding: 3px;
    border-radius: 5px;
    top: 5px;
    left: 5px;
    background: var(--theme-default);
}

.homepage_price_of_product {
    font-size: 20px;
    margin: 15px 0px;
}

.homepage_price_both_discounted_and_regular {
    display: flex;
    align-items: center;
}

.homepage_name_reference_of_product {
    font-size: 20px;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;

}

@media screen and (max-width:576px) {
    .homepage_name_reference_of_product {
        font-size: 3.5vw;
        /* font-family: lato !important; */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }
}


.cart_productt:hover .name_reference_of_product {
    text-decoration: underline;
}

.homepage_place_order_to_cart button {
    font-size: 1.25rem;
    background: var(--btn-back);
    color: var(--secondary-color);
    border-radius: 5px;
    border: none;
    width: 100%;
    /* font-family: lato !important; */
}


.hover_pic {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.homepage_card_top:hover .hover_pic {
    display: block;
    transition: 0.5sec;
}


.hompage_shopbyprcie_section_division {
    display: flex;
    width: 100%;
    justify-content: center;
}

.under_tag {
    margin-top: 5px;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .ratings_part {
        padding: 2px;
    }
}


.hpmepage_main_carousal .slick-dots {
    bottom: 0;
    position: absolute;
}

.hpmepage_main_carousal .slick-dots li button {
    background: #4f3267;

}



.hpmepage_main_carousal .slick-dots li.slick-active button {
    background: #4f3267;

}

.hpmepage_main_carousal .slick-next {
    right: 10px;
    position: absolute;
    color: white !important;
    background: transparent;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
}

.hpmepage_main_carousal .slick-prev {
    left: 10px;
    position: absolute;
    color: white !important;
    background: transparent;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(225deg);
    z-index: 99;
}

.image_size_1 {
    width: 250px;
    height: 250px;
}


.cat_main_div {
    display: flex;
    justify-content: space-between;
}

.cat_main_div_two {
    position: relative;
    display: flex;
    justify-content: center;
}

.cat_name {
    position: absolute;
    top: 10px;
    color: white;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
}

.exp_btn {
    position: absolute;
    bottom: 20px;
    color: black;
    background-color: white;
    border-radius: 0px;
}

.rec.rec-arrow {
    border-radius: 0;
    padding: 0;
}


.review_div {
    bottom: 5px;
    left: 5px;
    position: absolute;
    background-color: #4f3267;
    color: white;
    border-radius: 2px;
    padding: 2px;
}