.main_division_filterpage {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d0d0d0;
}

.sort_section {
    align-items: center;
    display: flex;
    justify-content: end;
}

@media only screen and (max-width:554px) {
    .sort_section{
        justify-content: start;
    }
}

.filter_section {
    display: flex;
}


.sort_by {
    font-size: 18px;
    font-weight: 600;
    margin: 0 2rem 0 0;
}

.filter_by {
    font-size: 18px;
    font-weight: 600;
    margin: 0 2rem 0 0;
}

.sortby_dropdown_1 {
    font-size: 18px;
    border: 1px solid var(--theme-default);
}

.sortby_dropdown_2 {
    font-size: 18px;
    border: 1px solid var(--theme-default);
}

.sortby_dropdown_2:focus {
    outline: none;
}

.sortby_dropdown_1:focus {
    outline: none;
}

.filter-by> :nth-child(2) {
    margin-left: 8px;
    cursor: pointer;
}

.category {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
}

.check_box {
    margin-top: 20px;
    padding: 0px 10px;
}

.check_box_container {
    width: 20px;
    height: 20px;
}

.check_box_label {
    padding-left: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.main_division {
    border-bottom: 1px solid #353535;
}

.ttt {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

@media only screen and (max-width:1024px) {

    .ttt {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }


}

@media only screen and (max-width:768px) {
    .ttt {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width:576px) {

    .ttt {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

}

.cardd_mainn {
    padding: 7px;
    border: 1px solid #e0e0e0;
}

.cardd_mainn:hover {
    border: 1px solid #878787;
}

.card_top {
    position: relative;
    /* height: 270px; */
    text-align: center;
    background-color: #efefef;
}

.card_top:hover .hover_pic {
    display: block;
    transition: 0.5sec;
}

.image_size {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
    /* padding: 30px; */
    aspect-ratio: 1;
    mix-blend-mode: darken;
}

.re {
    position: absolute;
    right: 10px;
    top: 10px;
}

@media only screen and (max-width:768px) {

    .name_reference_of_product_vertical_page {
        font-size: 16px;
    }

    .place_order_to_cart_vertical_page button {
        font-size: 13px;
    }

    .price_both_discounted_and_regular_vertical_page {
        display: block;
    }
}

.price_both_discounted_and_regular_vertical_page {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:819px) {
    .price_both_discounted_and_regular_vertical_page {
        display: block;
    }
}

.pagination {
    justify-content: end;
    display: flex;
    margin-top: 20px;
}

.add_to_cart_btn {
    text-transform: uppercase;
    background: var(--btn-back);
    color: white;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    border-radius: 0px;
}

.add_to_cart_btn:hover {
    background: var(--btn-back);
    color: white;
}

.form-check-input {
    background: none;
    border: 1px solid var(--secondary-color);
}

.form-check-label {
    margin-right: 50px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:focus-visible {
    border: transparent;
}

/* laptop_view_end */

/* FILTERPAGE CSS */

.featured {
    border: none !important;
    font-size: 20px;
}

.featured:focus-visible {
    outline: none !important;
}

.angle-up {
    width: 30%;
    text-align: end;
    /* padding-left: 100px; */
}

.deals {
    display: flex;
    margin-top: 10px;
}

/* filter_css_mobile */

.css-1jhmdt8-MuiButtonBase-root-MuiPaginationItem-root {

    border: 1px solid var(--secondary-color) !important;
}

.css-1jhmdt8-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    background-color: var(--theme-default) !important;
}

.css-1jhmdt8-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:focus {
    background-color: var(--theme-default) !important;
}

.css-zm4fy8.Mui-selected {
    color: var(--theme-default) !important;
    border: 1px solid var(--theme-default) !important;
}

.css-zm4fy8 {
    color: var(--second-secondary) !important;
    border: 1px solid var(--second-secondary) !important;
}

/* pagination_css_start */
.vertical_product_card_pagination_active {
    z-index: 0 !important;
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    margin: 0px 5px;
}

.vertical_product_card_pagination {
    background-color: var(--theme-default) !important;
    color: white !important;
    border-color: transparent !important;
    margin: 0px 5px;
}

.vertical_product_card_pagination_arrow {
    background-color: var(--theme-default) !important;
    color: white !important;
    border-color: transparent !important;
    margin: 0px 5px;
}

.name_reference_of_product_vertical_page {
    font-size: 20px;
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.le {
    position: absolute;
    color: white;
    padding: 3px;
    border-radius: 5px;
    top: 5px;
    left: 5px;
    background: var(--theme-default);
    font-size: 12px;
}