.thank_you_container {
    text-align: center;
    margin-top: 50px;
}

.thankkyou {
    font-size: 75px;
    font-weight: 600;
    color: #BB9854;
}

.thankkyou_2 {
    font-size: 20px;
    font-weight: 400;

}

.thankkyou_3 {
    font-weight: 700;
}

.button_container {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_home {
    border: 1px solid #5C5C5C;
    cursor: pointer;
    padding: 10px 42px;
    margin-right: 42px;
}

.button_order_thankyou {
    border: 1px solid #5C5C5C;
    padding: 10px 42px;
    cursor: pointer;
}