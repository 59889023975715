

.orderdetail_main_division {
  /* margin-left: 160px; */
  /* width: 80%; */
  padding: 20px;
}

.orderdetail_title_button_field {
  display: flex;
}

.orderdetail_edit_order_title {
  font-size: 25px;
  color: var(--ecom-admin-font-color);
}

.orderdetail_button_field {
  display: flex;
  align-items: center;
}

.orderdetail-btn-add-order {
  color: var(--ecom-admin-font-color);
  background-color: var(--ecom-admin-secondary-color);
  border-radius: 3px;
  height: 30px;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--ecom-admin-font-color);
  /* margin-left: 10px; */
}

.orderdetail_sub_main_division {
  margin-top: 10px;
  display: flex;
}

.orderdetail_first_main_division {
  width: 75%;
  margin-right: 30px;
}

.orderdetail_first_sub_main_division {
  border: 1px solid var(--ecom-admin-font-color);
  border-radius: 10px;
  margin-bottom: 20px;
  background: var(--back-color);
}

.orderdetail_sub_title {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--ecom-admin-light-border);
}

.orderdetail_item_title {
  width: 100%;
  color: var(--ecom-admin-second-color);
}

.orderdetail_cost_title {
  width: 10%;
  color: var(--ecom-admin-second-color);
}

.orderdetail_qty_title {
  width: 20%;
  color: var(--ecom-admin-second-color);
}

.orderdetail_total_title {
  width: 20%;
  color: var(--ecom-admin-second-color);
}

.orderdetail_product_order_detail {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--ecom-admin-light-border);
}

.orderdetail_order_img {
  height: 100px;
}

.orderdetail_order_title_detail {
  display: flex;
  align-items: center;
  /* padding: 10px; */
}

.orderdetail_img_product_detail_division {
  width: 100%;
  display: flex;
}

.orderdetail_iphone_title {
  color: var(--ecom-admin-font-color);
  margin-bottom: 5px;
}

.orderdetail_sku_division {
  display: flex;
  margin-bottom: 5px;
}

.orderdetail_sku_title {
  color: var(--ecom-admin-second-color);
  font-size: 15px;
  font-weight: 500;
}

.orderdetail_sku_detail {
  color: var(--ecom-admin-second-color);
  font-size: 15px;
  font-weight: 300;
}

.orderdetail_variation_division {
  display: flex;
  margin-bottom: 5px;
}

.orderdetail_variation_title {
  color: var(--ecom-admin-second-color);
  font-size: 15px;
  font-weight: 500;
}

.orderdetail_cost_value {
  width: 10%;
  font-size: 13px;
  margin-top: 10px;
}

.orderdetail_qty_value {
  width: 20%;
  font-size: 14px;
  margin-top: 10px;
}

.orderdetail_total_value {
  width: 20%;
  font-size: 13px;
  margin-top: 10px;
}

.orderdetail_title {
  display: flex;
  align-items: center;
}

.orderdetail_title_tag {
  border: 1px solid #8f7d4f;
  margin-left: 10px;
  font-size: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 75%;
  margin-bottom: 5px;
}

.orderdetail_coupon_desc {
  font-size: 12px;
}

.orderdetail_order_title_division {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--ecom-admin-light-border);
}

.orderdetail_sub_total_title {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 14px;
}

.orderdetail_order_price_division {
  width: 10%;
  display: flex;
  justify-content: end;
}

.orderdetail_price_title {
  font-size: 14px;
  margin-top: 5px;
}

.orderdetail_price_value {
  font-weight: 700;
  margin-top: 5px;
}

.orderdetail_button_msg_division {
  display: flex;
  padding: 10px;
}

.orderdetail_refund_button {
  width: 75%;
  display: flex;
  align-items: center;
}

.orderdetail_last_msg {
  font-size: 15px;
  font-weight: 300;
  text-align: end;
}

.orderdetail_second_sub_main_division {
  border: 1px solid var(--ecom-admin-font-color);
  padding: 20px 10px;
  border-radius: 10px;
  background: var(--back-color);
}

.orderdetail_order_detail_title {
  color: var(--ecom-admin-font-color);
  font-size: 25px;
}

.orderdetail_order_detail_sub_title {
  font-size: 18px;
  color: var(--ecom-admin-second-color);
}

.orderdetail_second_sub_division {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.orderdetail_general_main_division {
  width: 33.33%;
}

.orderdetail_general_title {
  color: var(--ecom-admin-font-color);
  font-weight: 600;
}

.orderdetail_date_created_title {
  color: var(--ecom-admin-second-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  /* margin-right: 55px; */
  width: 30%;
}

.orderdetail_date_field_division {
  display: flex;
}

.orderdetailformControl {
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-color);
}

.orderdetailtextformControl {
  height: 30px;
  width: 50px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-color);
}

.orderdetailstatusformControl {
  height: 30px;
  width: 300px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-color);
  margin-bottom: 10px;
}

.orderdetail_profile_tag {
  color: var(--ecom-admin-font-color);
  font-size: 13px;
  margin-top: 15px;
  /* margin-left: 10px; */
}

.orderdetail_customer_search_field {
  border: 1px solid var(--ecom-admin-second-color);
  border-radius: 5px;
  padding: 5px;
}

.orderdetail_user_id_name {
  color: var(--ecom-admin-second-color-secondary);
  font-size: 13px;
  margin-left: 5px;
}

.orderdetailuseridformControl {
  height: 35px;
  width: 290px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0rem 0.6rem;
  background: var(--ecom-admin-secondary-color);
  color: var(--ecom-admin-second-secondary-color);
  border: 1px solid var(--ecom-admin-second-color);
  margin-top: 10px;
}

.orderdetail_error_msg {
  font-size: 13px;
  margin-top: 15px;
}

.orderdetail_Billing_main_division {
  width: 33.33%;
  margin-left: 20px;
}

.orederdetail_billing_title {
  width: 100%;
  color: var(--ecom-admin-font-color);
  font-weight: 600;
}

.orderdetail_edit_icon {
  color: var(--ecom-admin-second-color);
}

.orderdetail_user_details {
  color: var(--ecom-admin-second-color);
  font-weight: 300;
  font-size: 13px;
}

.orderdetail_email_heading {
  color: var(--ecom-admin-second-color);
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}

.orderdetail_email {
  color: var(--ecom-admin-font-color);
  font-weight: 400;
  font-size: 13px;
}

.orderdetail_shipping_title {
  width: 100%;
  color: var(--ecom-admin-font-color);
  font-weight: 600;
}

.orderdetail_shipping_main_division {
  width: 33.33%;
  margin-left: 20px;
}

.orderdetail_shipping_title_icon_field {
  display: flex;
  padding-bottom: 15px;
}

.orderdetail_address_field {
  color: var(--ecom-admin-second-color);
  font-size: 14px;
  font-weight: 500;
}

.orderdetail_shipping_address_msg {
  font-size: 13px;
  color: var(--ecom-admin-second-color);
}

/* order_detail_right_css_start */

.order_detail_second_main_deivion {
  width: 25%;
}

.order_detail_send_order_mail_division {
  height: max-content;
  border: 1px solid var(--ecom-admin-font-color);
  border-radius: 10px;
  background-color: var(--ecom-admin-secondary-color);
}

.order_detail_send_order_mail {
  padding: 10px 15px;
  margin-bottom: 5px;
  color: var(--ecom-admin-font-color);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-border);
}

.order_detail_save_order_dropdown {
  margin: 20px 6px;
  background-color: var(--ecom-admin-secondary-color);
  border: 1px solid var(--ecom-admin-border);
  width: 95%;
  /* padding: 0px 5px!important; */
}

.order_detail_save_order_dropdown:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.order_detail_save_order_dropdown_menu:hover {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.save_order_send_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 25px 10px !important;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(255, 85, 0) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.accept_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: green !important;
  color: var(--ecom-admin-secondary-color) !important;
}
.reject_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: red !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.save_order_send_button:focus {
  box-shadow: none;
}

.save_order_send_button:hover {
  color: var(--ecom-admin-secondary-color);
}

.order_detail_order_action_mail_division {
  margin-top: 20px;
  height: max-content;
  border: 1px solid var(--ecom-admin-second-color);
}

.order_detail_order_action {
  padding: 10px 15px;
  margin-bottom: 0px;
  color: var(--ecom-admin-font-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-border);
}

.bgColorRed {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.order_detail_order_action_dropdown {
  margin: 20px 6px;
  background-color: var(--ecom-admin-secondary-color);
  border: 1px solid var(--ecom-admin-border);
  width: 95%;
}

.order_detail_order_action_dropdown:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.order_detail_order_action_dropdown_menu:hover {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.order_detail_movetotrash_division {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid var(--ecom-admin-border);
}

.order_detail_movetotrash {
  color: var(--ecom-admin-font-color);
  text-decoration: underline;
}

.order_detail_order_action_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--ecom-admin-font-color) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.order_detail_order_action_button:focus {
  box-shadow: none;
}

.order_detail_order_action_button:hover {
  color: var(--ecom-admin-secondary-color);
}

.order_detail_create_pdf_mail_division {
  margin-top: 20px;
  height: max-content;
  border: 1px solid var(--ecom-admin-second-color);
}

.order_detail_create_pdf {
  padding: 10px 15px;
  margin-bottom: 0px;
  color: var(--ecom-admin-font-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-border);
}

.order_detail_create_pdf_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  margin: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--ecom-admin-font-color) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.order_detail_create_pdf_button:focus {
  box-shadow: none;
}

.order_detail_create_pdf_button:hover {
  color: var(--ecom-admin-secondary-color);
}

/* order-action-css-start */
.order_detail_order_notes_main_division {
  margin-top: 20px;
  height: max-content;
  border: 1px solid var(--ecom-admin-second-color);
}

.order_detail_order_notes_division {
  height: 300px;
  padding: 10px 10px;
  border-bottom: 1px solid var(--ecom-admin-border);
  overflow-y: scroll;
}

.box {
  /* margin-left: 20px; */
  width: 100%;
  height: 80vh;
  /* margin: auto; */
  position: relative;
  display: block;
}

.dialog-1 {
  height: 60px;
  width: 100%;
  background-color: var(--ecom-admin-font-color);
  position: absolute;
  border-radius: 5px;
}

.left-point {
  width: 0;
  height: 0;
  border-left: 2vh solid transparent;
  border-right: 8vh solid transparent;
  border-top: 12vh solid var(--ecom-admin-font-color);
  position: absolute;
  top: 45%;
  left: 18px;
  transform: rotate(1deg);
}

.order_detail_adminorder_note_text_division {
  position: relative;
}

.order_detail_adminorder_note_text {
  color: var(--ecom-admin-second-secondary-color);
  font-size: 16px;
  padding: 5px 0px;
  margin-bottom: 0;
}

.order_detail_adminorder_text_data {
  color: var(--ecom-admin-second-color);
  margin-bottom: 0;
  font-size: 14px;
}

.order_detail_order_usersidenote_text_division {
  position: relative;
}


.order_detail_order_usersidenote_text {
  color: var(--ecom-admin-second-secondary-color);
  font-size: 16px;
  padding: 5px 0px;
  margin-bottom: 0;
}

.order_detail_usersidenote_text_data {
  color: var(--ecom-admin-second-color);
  padding: 7px 10px;
  margin-bottom: 0;
  font-size: 14px;
}

.order_detail_order_bydefaultnote_text_division {
  background: var(--ecom-admin-border);
  padding: 10px;
  position: relative;
}

.order_detail_order_bydefaultnote_text_division::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  left: 23px;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 0;
  border-style: solid;
  border-color: var(--ecom-admin-border);
  z-index: -1;
  transform: rotate(135deg);
}

.order_detail_order_bydefaultnote_text {
  color: var(--ecom-admin-second-secondary-color);
  font-size: 16px;
  padding: 5px 0px;
  margin-bottom: 0;
}

.order_detail_bydefaultnote_text_data {
  color: var(--ecom-admin-second-color);
  padding: 7px 10px;
  margin-bottom: 0;
  font-size: 14px;
}

.order_detail_order_note {
  padding: 10px 15px;
  margin-bottom: 0px;
  color: var(--ecom-admin-font-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-border);
}

.order_detail_add_note_division {
  color: var(--ecom-admin-font-color);
  font-size: 18px;
  padding: 10px;
}

.order_detail_add_note_textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--ecom-admin-font-color);
}

.order_detail_order_notes {
  padding: 10px 15px;
  margin-bottom: 0px;
  color: var(--ecom-admin-font-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--ecom-admin-border);
}

.order_detail_private_note_division {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}

.order_detail_order_note_dropdown {
  background-color: var(--ecom-admin-secondary-color);
  border: 1px solid var(--ecom-admin-border);
  width: 200px;
}

.order_detail_order_note_dropdown:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.order_detail_order_note_dropdown_menu:hover {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.order_detail_order_note_button {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--ecom-admin-font-color) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.order_detail_order_note_button:focus {
  box-shadow: none;
}

.order_detail_order_note_button:hover {
  color: var(--ecom-admin-secondary-color);
}

.orderdetail_line_main_div {
  display: flex;
}


@media only screen and (max-width: 768px) {
  .order_detail_private_note_division {
    display: block;
  }

  .orderdetail_line_main_div {
    display: block;
  }

  .save_order_send_button {
    font-size: 14px;
  }
  .order_detail_order_note_dropdown {
    width: 160px;
    margin-bottom: 5px;
  }

  .order_detail_order_action_button {
    padding: 5px 2px;
  }
}



.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}