/* breadcrumbs_css_start */

.breadcrumbs_product_divition {
    font-size: 14px;
    font-weight: 200;
    cursor: pointer;
}

.breadcrumbs_product_arrow_icone {
    margin-left: 15px;
    font-size: 14px;
}

.breadcrumbs_product_path_details {
    margin-left: 15px;
    font-size: 14px;
    text-transform: capitalize !important;
    cursor: pointer;
}

.detail_ {
    display: flex;
    align-items: center;
}

.select_variant {
    width: 50%;
}

.select_text {
    font-size: 15px;
    height: 45px;
    background: #cfcfff;
    padding: 8px 15px;
    font-weight: bold;
    border: none;
}

.select_text:focus-visible {
    outline: none;
}





.fav_icon_product_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.product_page_product_name {
    display: flex;
    justify-content: space-between;
}

.product_name_pp {
    color: var(--theme-default-two);
    font-size: 1.563em;
    font-weight: 500;
    text-transform: capitalize;
}


.cart_button_product_page {
    display: flex;
    color: var(--black-background-color);
    margin: 20px 0px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: none !important;
}

.cart_product_inside {
    background: var(--btn-back);
    color: var(--secondary-color);
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0px;
}

.cart_product_inside:hover {
    background: var(--btn-back);
    color: var(--secondary-color);
}


.description_product_div {
    border: none;
    border-bottom: 1px solid rgb(157, 149, 149);

}

.product_acc_des {
    box-shadow: none !important;
    background: none !important;
    color: var(--black-background-color) !important;
}

.product_acc_des:focus {
    box-shadow: none !important;
    background: none !important;
    border: none;
    color: var(--black-background-color);
}




@media only screen and (max-width:720px) {


    .fav_icon_product_page {
        display: block;
    }

}

@media only screen and (max-width:769px) {
    .detail_ {
        display: block;
    }


}



.heading_featured_product {
    margin-top: 20px;
    text-align: center;
}


.block_main {
    display: flex;
    justify-content: space-between;
}

.detail_product {
    width: 50%;
    margin-left: 10px;
}


.left_product_image {
    width: 50%;
    position: relative;
}



@media only screen and (max-width:768px) {

    .block_main {
        display: block;
    }

    .left_product_image,
    .detail_product {
        width: 100%;
        margin-left: 0px;
    }
}

/* swiper css start */

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper {
    width: 100%;
    height: 145px;
    padding: 10px 0;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwipermain {
    height: 40%;
    width: 80%;
    border: 1px solid #e0e0e0;
}


.mySwipermulti .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    border: 1px solid #e0e0e0;
}

.mySwipermulti .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper_pervious {
    position: absolute;
    top: 15%;
}

.swiper_next {
    position: absolute;
    right: 0;
    top: 15%;
}

/* swiper css end */