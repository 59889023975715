.top_main_div {
    background-color: var(--theme-default-two);
    color: var(--secondary-color);
}





.s{
    text-align: center !important;
}