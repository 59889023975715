.login_style_popup_mobile-content {

    box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
    padding: 30px 40px;
    width: auto;
    height: max-content;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .login_style_popup_mobile-content {
        padding: 30px 20px !important;
        width: 100%;
        height: max-content;
    }

}

.loginpage_main_division {
    background-color: var(--black-background-color);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 30px;
    width: 35%;
    margin: 130px 450px;
}

.loginpage_cancel {
    color: var(--black-background-color);
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.loginpage_login_account_field {
    display: flex;
    border: 1px solid var(--black-background-color);
    border-radius: 5px;
    margin: 0 30px;
    height: 50px;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .loginpage_login_account_field {
        margin: 10px 10px 0px 10px;
    }

}

.loginpage_login {
    color: var(--theme-default);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginpage_OR {
    color: var(--theme-default);
    width: max-content;
    height: max-content;
    margin-top: 8px;
    padding: 3px 8px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--black-background-color);
    border-radius: 5px;
    align-items: center;

}

.loginpage_create_account {
    color: var(--theme-default);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginpage_email_number_title {
    color: var(--black-background-color);
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;
}

.loginpage_email_number_field {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.nineonefield {
    margin-Right: 5px;
    padding: 5px;
    font-Size: 16px;
    border: none;
    width: 20%;
    text-Align: center;
    border-radius: 5px;
    border: 1px solid var(--black-background-color) !important;
}

.nineonefield:focus{
    outline: none;
}

.loginpageformControl {
    height: 3rem;
    width: 100%;
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1.8rem;
    padding: 1.4rem 1.2rem;
    border: 1px solid var(--black-background-color) !important;
}


.loginpage-form-check {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.loginpage-form-check-label {
    color: var(--secondary-color);
    margin-left: 5px;
}

.loginpage_terms_privacy {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media only screen and (min-width:320px) and (max-width:425px) {
    .loginpage_terms_privacy {
        display: block;
    }

}

.loginpage_agree {
    color: var(--black-background-color);
    font-size: 13px;
    text-align: center;
}

.loginpage_terms {
    color: var(--theme-default);
    font-weight: 700;
    font-size: 13px;
    margin-left: 5px;
}

.loginpage_and {
    color: var(--theme-default);
    font-size: 13px;
    margin-left: 5px;
}

.loginpage_privacy {
    color: var(--theme-default);
    font-weight: 700;
    font-size: 13px;
    margin-left: 5px;

}

.loginpage_spinner_loader {
    display: flex;
    justify-content: center;
    width: 100%;
}

.loginpage_continue_button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.loginpage_continue {
    color: var(--secondary-color);
    background: var(--second-secondary);
    border-radius: 10px;
    height: 55px;
    width: 75%;
    font-size: 17px;
    font-weight: 700;
}

.loginpage_continue:hover {
    color: var(--secondary-color);
    background: var(--second-secondary);
}

.spinner {
    display: flex;
    justify-content: center;
    width: 100%;
}



/* otp section start */



.otpverify_main_division {
    background-color: var(--black-background-color);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 30px;
    width: 35%;
    margin: 130px 450px;
}


.btn-close {
    display: flex;
    justify-content: start;
}

.otpverify_title {
    color: var(  --black-background-color);
    font-size: 23px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.otpverify_subtitle {
    color: var(  --black-background-color);
    font-size: 15px;
    font-weight: 300;
    display: flex;
    justify-content: center;
}

.card-input-container {
    position: relative;
    margin: 0.5rem 0;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.otp_formControl {
    height: 3.2rem !important;
    width: 100%;
    border-radius: 10px;
    text-align: center !important;
    font-weight: 700;
    background: var(--black-background-color) !important;
    border: 1px solid var(--secondary-color);
    font-size: 22px !important;
    color: var(--secondary-color) !important;
    background-color: var(--black-background-color);
    display: flex;
    justify-content: end;
}

.otp_formControl:focus-visible {
    border: 1px solid transparent !important;
}

.otp_field_section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 90px !important;
}

@media only screen and (max-width:425px) {
    .otp_field_section {
        margin-top: 60px;
        width: 69px;
    }

}

.otpverify_time {
    color: var(  --black-background-color);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-top: 30px;
}

.otpverify_resend_otp {
    display: flex;
    justify-content: center;
}

.otpverify_receive_otp {
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
}

.resend_otp {
    color: var(--theme-default);
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}


.loginpage_continue_selected {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    border-radius: 10px;
    height: 55px;
    width: 75%;
    font-size: 17px;
    font-weight: 700;
}

.loginpage_continue_selected:hover {
    color: var(--secondary-color);
    background-color: var(--theme-default);
}


/* otp section end */