.profile_page {
    display: flex;
}

.profile_path {
    color: black;
    font-size: 14px;
    font-weight: 200;
}


.profile_path_icon {
    color: var(--second-secondary);
    margin-left: 15px;
    font-size: 14px;
}

@media only screen and (max-width:425px) {
    .profile_path_icon {
        margin-left: 5px;
    }
}

.profile_path_details {
    color: black;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

@media only screen and (max-width:425px) {
    .profile_path_details {
        margin-left: 5px;
    }
}

.profile_extra_division {
    padding-top: 10px;
}

.profile_second_extra_division {
    padding: 5% 10% -1%;

}

@media only screen and (max-width:1024px) {
    .profile_second_extra_division {
        padding: 0px;
        display: block;
    }
}


.profile_page_title {
    color: var(--theme-default);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    border-bottom: 1px solid #C7C6C6;
}

@media only screen and (max-width:425px) {
    .profile_page_title {
        margin-bottom: 0%;
        font-size: 20px;
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .profile_page_title {
        font-size: 25px;
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media only screen and (min-width:1024px) and (max-width:1439px) {
    .profile_page_title {
        margin-left: 0px;
        margin-top: 0px;
    }
}



.user_header_cart:hover {
    color: var(--theme-default) !important;
}

@media only screen and (max-width:425px) {
    .profilprofile_page_division_cole_name_category {
        padding: 0px;
    }
}

.profile-form-label {
    color: black;
    padding-bottom: 10px;
}

@media only screen and (max-width:425px) {
    .profile-form-label {
        padding-bottom: 0px;
    }

}

.profile_name_cat_dropdown {
    height: 3.2rem;
    font-size: 0.9rem;
    box-shadow: none;
    width: 50%;
}










.profile_radio_button_division {
    display: flex;
    color: var(--secondary-color);
    margin-top: 30px;
    /* margin-bottom: 10px; */

}

.profile_radio_button {
    display: flex !important;
    margin-bottom: 0px !important;
}

.form-check-input {
    background: none;
    border: 1px solid var(--secondary-color);
}

.form-check-label {
    margin-right: 50px;
    color: black;
}


.form-check-input:focus-visible {
    border: transparent;
}

.profile-button {
    border-top: 1px solid #C7C6C6;
    display: flex;
    justify-content: end;
}


.profile-btn-discard {
    color: var(--secondary-color);
    margin-top: 10px;
    background-color: var(--black-background-color);
    font-size: 17px;
    font-weight: 700;
    border: none;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    align-items: center;
}

@media screen and (max-width:325px) {
    .profile-btn-discard {
        font-size: 10px;
    }

    .profile-btn-save {
        font-size: 10px !important;
    }
}


.profile-btn-save {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    margin-top: 10px;
    font-size: 17px;
    font-weight: 700;
    margin-left: 10px;
    border: none;
    padding: 10px;
}



.profile-add-right {
    position: relative;
}



.profile-input-group-addon {
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-46%);
    transform: translateY(-48%);
    color: var(--arihant-second-border-color);
    font-size: 0.9rem;
}



.profile_email_popup_division {
    display: flex;
    justify-content: center;
    width: 500px;
}

.profile-input-group-right {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0.6rem 1.9rem;
    border: none;
    border-radius: 10px;
    background-color: transparent;
}


@media only screen and (min-width:425px) and (max-width:767px) {
    .profile-input-group-right {
        width: 21%;
        margin-top: 1%;
    }
}

.userprofileformControl {
    height: 3.2rem;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    box-shadow: none;
}



.userprofilenumberformControl {
    height: 3.2rem;
    width: 100%;
    border-radius: 10px;
    font-size: 0.9rem;
    padding: 1.4rem 4.0rem;
    font-weight: 400;
    box-shadow: none;
    background: #E6E4E6;
    color: var(--arihant-second-border-color);
}

.userprofilenumberformControl:focus {
    background: #E6E4E6;
}

.userprofileformControl:focus {
    border: 1px solid var(--theme-default);
}

.form-select:focus {
    box-shadow: none;
    border: 1px solid var(--theme-default);
}

.userprofilenumberformControl:focus-visible {
    border-color: transparent;
}

.profile_number_popup {
    background-color: var(--black-background-color);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    width: 30%;
    margin: 250px 450px;
    padding: 20px;
}

.profile_change_number {
    display: flex;
    padding: 20px;
}

@media only screen and (max-width:425px) {
    .profile_change_number {
        padding: 10px;
    }
}

.profile_send_otp_button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.profile-send-otp {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    border-radius: 10px;
    border: none;
    height: 44px;
    width: 50%;
    font-size: 17px;
    font-weight: 700;
    margin-left: 10px;
}

.popup_cancel {
    color: var(--black-background-color);
    justify-content: end;
    display: flex;
    cursor: pointer;
}

.popup_cancel:hover {
    color: var(--black-background-color);
}

.profile-new-change-number-form-label {
    color: var(--black-background-color);
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

.profile-number-add-right {
    position: relative;

}

.profile-number-input-group-addon {
    position: absolute;
    top: 35%;
    left: 1.5rem;
    -webkit-transform: translateY(-46%);
    transform: translateY(-48%);
    font-size: 0.9rem;
}

.userprofileeditnumberformControl {
    height: 3.2rem;
    width: 350px;
    border-radius: 10px;
    font-size: 0.9rem;
    padding: 1.4rem 3.5rem;
    font-weight: 400;
    background: var(--secondary-color);
}

@media only screen and (max-width:425px) {
    .userprofileeditnumberformControl {
        width: 300px;
    }
}

@media only screen and (max-width:320px) {
    .userprofileeditnumberformControl {
        width: 250px;
    }
}

.userprofileeditemailformControl {
    height: 3.2rem;
    width: 350px;
    border-radius: 10px;
    font-size: 0.9rem;
    padding: 1.4rem 1rem;
    font-weight: 400;
    background: var(--secondary-color);
}

@media only screen and (max-width:425px) {
    .userprofileeditemailformControl {
        width: 300px;
    }
}

@media only screen and (max-width:320px) {
    .userprofileeditemailformControl {
        width: 250px;
    }
}

.profile_error {
    color: var(--theme-default);
    font-size: 15px;
    padding-left: 5px;
    font-weight: 500;

}