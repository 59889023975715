
.editpromocode_main_division {
    width: 80%;
    background-color: var(--ecom-admin-secondary-color) !important;
}

.editpromocode_main_sub_division {
    margin: 30px 20px;
}

.editpromocode_add_new_product_title {
    font-size: 25px;
    margin-bottom: 10px;
    color: var(--ecom-admin-font-color);
}

.editpromocode_sub_main_division {
    display: flex;
}

.editpromocode_add_new_product_division {
    width: 75%;
}

.editpromocode_formcontrol {
    width: 100%;
    border-radius: 5px;
    font-size: 22px;
    padding: 3px 10px;
    background: var(--ecom-admin-secondary-color) !important;
    background-color: var();
}

.editpromocode_formcontrol:focus-visible {
    border: 1px solid var(--ecom-admin-border) !important;
    color: var(--ecom-admin-second-color-secondary);
}

.editpromocode_permalink_breadcrums {
    display: flex;
    margin: 10px 0px 15px 0px;
}

.editpromocode_description_form {
    margin-bottom: 30px;
    width: 100%;
    border-radius: 5px;
    font-size: 22px;
    padding: 3px 10px;
    background: var(--ecom-admin-secondary-color) !important;
    background-color: var();
}

.editpromocode_description_form:focus-visible {
    border: 1px solid var(--ecom-admin-border) !important;
    color: var(--ecom-admin-second-color-secondary);
}

.editpromocode_generate_code_btn {
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    border: none;
    border: 1px solid var(--ecom-admin-font-color);
    cursor: pointer;
    color: var(--ecom-admin-font-color);
}

/* start product data section */


.editpromocode_product_data_main_division {
    border: 1px solid var(--ecom-admin-border);
    margin-bottom: 20px;
}

.editpromocode_product_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-light-border);
}

.editpromocode_product_data_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-font-color);
}

.editpromocode_category_list_details {
    display: flex;
}

.editpromocode_category_list {
    width: 20%;
    border-right: 1px solid var(--ecom-admin-light-border);
}

.editpromocode_category_active {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
    border-bottom: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
}

.editpromocode_category {
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--ecom-admin-second-secondary-color);
    border-bottom: 1px solid var(--ecom-admin-light-border);
    cursor: pointer;
}

.editpromocode_category:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

/* Addcoupon general section */

.editpromocode_coupondata_main_division {
    width: 80%;
    padding: 10px;
}

.editpromocode_coupondata_division {
    display: flex;
    align-items: start;
    margin-bottom: 10px;
}

.editpromocode_coupondata_left_side {
    width: 25%;
}

.editpromocode_coupondata_right_side {
    width: 75%;
}

.editpromocode_discounttype_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-font-color);
    color: var(--ecom-admin-font-color);
    width: 50%;
    text-transform: capitalize;
}

.editpromocode_discounttype_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}
.editpromocode_discounttype_dropdown:hover{
    color: var(--ecom-admin-font-color);
}

.editpromocode_discounttype_dropdown_menu_active{
    text-transform: capitalize;
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}
.editpromocode_discounttype_dropdown_menu_active:hover{
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
}
.editpromocode_discounttype_dropdown_menu{
    text-transform: capitalize;
    color: var(--ecom-admin-font-color);
}
.editpromocode_discounttype_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color) !important;
    color: var(--ecom-admin-secondary-color) !important;
}

.editpromocode_couponamount_text_field {
    width: 50%;
    padding: 8px 10px;
    font-size: 14px;
}

.editpromocode_couponamount_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.editpromocode_allow_free_shipping_chechbox{
    width: 50px;
}
.editpromocode_allow_free_shipping_chechbox_label span{
    color: var(--ecom-admin-font-color);
}

/* star usage rescrisation */

.editpromocode_usage_main_division {
    width: 75%;
}

.editpromocode_usage_division{
    border-bottom: 1px solid var(--ecom-admin-border);
    padding: 10px;
}

.editpromocode_usage_sub_main_division {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.editpromocode_minimum_spend_title {
    width: 20%;
    display: flex;
    align-items: center;
}

.editpromocode_minimum_spend_title_text_field {
    width: 50%;
    padding: 8px 10px;
    font-size: 14px;
}

.editpromocode_minimum_spend_title_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.editpromocode_question_icon{
    display: flex;
    align-items: center;
    width: 30%;
    margin-left: 10px;
}

.editpromocode_usage_sub_main_division1 {
    display: flex;
    width: 100%;
    padding: 10px;
}

.editpromocode_usage_checkbox_sub_main_division{
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.editpromocode_check_box_division{
    display: flex;
    align-items: center;
}

.editpromocode_usage_sub_main_division2{
    display: flex;
    width: 100%;
}

/* star usage limit */

.editpromocode_limit_main_division {
    width: 75%;
    padding: 10px;
}

.editpromocode_limit_sub_main_division {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.editpromocode_limit_minimum_spend_title {
    width: 20%;
    display: flex;
    align-items: center;
}

.editpromocode_limit_minimum_spend_title_text_field {
    width: 50%;
    height: 30px;
    font-size: 14px;
}

.editpromocode_limit_minimum_spend_title_text_field:focus {
    box-shadow: none;
    border-color: var(--ecom-admin-border) !important;
    background-color: var(--ecom-admin-secondary-color) !important;
    color: var(--ecom-admin-second-secondary-color) !important;
}

.editpromocode_limit_question_icon{
    display: flex;
    align-items: center;
    width: 30%;
    margin-left: 10px;
}

.editpromocode_limit_sub_main_division1 {
    display: flex;
    width: 100%;
    padding: 10px;
}



/* start publish section */


.editpromocode_main_Social_division {
    width: 25%;
    margin: 0 20px 0 20px;

}

.editpromocode_main_publish_division {
    border: 1px solid var(--ecom-admin-border);
    margin-bottom: 20px;
}

.editpromocode_publish_data {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border);
}

.editpromocode_publish_title {
    font-weight: 500;
    width: 100%;
    color: var(--ecom-admin-second-secondary-color);
}

.editpromocode_publish_details_field {
    padding: 10px;
    border-bottom: 1px solid var(--ecom-admin-border);
}

.editpromocode_publish_field_button {
    display: flex;
}

.editpromocode-btn-save-draft {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    width: 28%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
}

.editpromocode-btn-preview {
    color: var(--ecom-admin-font-color);
    background-color: var(--ecom-admin-secondary-color);
    border-radius: 5px;
    height: 30px;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
    margin-left: 140px;
}

.editpromocode_edit_status {
    display: flex;
    margin-top: 10px;
}

.editpromocode_status_icon {
    color: #7c7c7d;
    padding-right: 5px;
}

.editpromocode_status {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.editpromocode_draft {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.editpromocode_edit {
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: var(--ecom-admin-font-color);
}

.editpromocode_last_division_new_draft {
    padding: 10px;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    justify-content: end;
}

.editpromocode_new_draft {
    color: var(--ecom-admin-second-secondary-color);
    padding-bottom: 5px;
}

.editpromocode_move_button {
    display: flex;
}

.editpromocode_move_trash {
    color: var(--ecom-admin-font-color);
}

.editpromocode-btn-publish {
    color: var(--ecom-admin-secondary-color);
    background-color: var(--ecom-admin-font-color);
    border-radius: 5px;
    height: 30px;
    width: 25%;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid var(--button-color);
    margin-left: 140px;
}

/* end publish section */