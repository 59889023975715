

.VerticalStepper {
  text-align: center;
}

.verticalstepper_title_name{
  padding-top: 5px;
}

.verticalstepper_in_progress_lebel{
    background-color: var(--theme-default);
    border-radius: 20px;
    padding: 5px;
    margin-top: 10px;
}