

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto ;
}

.addproduct_image_gallery_division {
    border: 1px solid var(--ecom-admin-second-color);
    margin: 10px;
    width: 70px;
    height: 70px;
    padding: 5px;
    /* display: flex; */
}

.addproduct_close_division {
    display: flex;
    justify-content: end;
    color: var(--ecom-admin-font-color);
    font-size: 10px;
    cursor: pointer;
}

.addproduct_galleryimg {
    width: 100%;
    height: 90%;
    object-fit: contain;
}