

.wishlist_cross_btn{
    background-color: var(--black-background-color);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    width: max-content;
    border-radius: 5px;
    padding: 5px;
}
.wishlist_cancel{
    color: var(--secondary-color);
    display: flex;
    justify-content: end;
}
.wishlist_remove_msg{
    color: var(--secondary-color);
    padding-right: 20px;
    padding-bottom: 5px;
    font-size: 15px;
}
.wishlist_msg_icon{
    display: flex;
}
.wishlist_mobile_image{
    height: 20px;
    padding-right: 5px;
}