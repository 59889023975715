.footer_main_division {
    background-image: url(../../../images/footer-bg.png);
    padding: 5% 12%;
    margin-top: 10px;
}




.form-control:focus {
    box-shadow: none;
}

.footer_social_icon_division {
    /* color: white; */
    display: flex;
}



.footer_social_icon_instagram a {
    /* width: 15%; */
    padding: 0px 5px;
    font-size: 22px;
    color: black !important;
}

.footer_social_icon_instagram:hover {
    color: black !important;
}



.footer_social_icon a {
    padding: 0px 10px;
    font-size: 22px;
    color: black;
}

.footer_social_icon a:hover {
    color: black !important;
}




.footer_copyright_division {
    display: flex;
    justify-content: center;
    background-image: url(../../../images/footer-bg.png);
    padding: 10px;
}

.footer_copyright {
    color: var(--black-background-color);
    font-weight: 600;
    font-size: 15px;
    padding-right: 5px;
    /* display: flex; */
    align-items: center;
}









.footer_first_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 26px;
}


.footer_quick_links_items {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 13px;
    cursor: pointer;
}

.footer_contact_us {
    font-size: 20px;
    margin-bottom: 13px;
    font-weight: 400;
}


.footer_second_main_section {
    margin-top: 80px;
}

.footer_subscribe_title {
    font-size: 1.5rem;
}

.application_link {
    display: flex;
}

.google_play {
    height: 50px;
    margin-right: 10px;
}

.app_store {
    height: 50px;
}



.keep_in_touch {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: 20px;
}

.email_footer {
    display: flex;
    margin-top: 10px;
}

.right_mail {
    width: 20px;
    cursor: pointer;
    text-align: center;
}

.button_right {
    height: 45px;
    margin-left: 10px;
    text-align: center;
    background-color: black;
    border-radius: 15px;
}

.button_right:focus {
    box-shadow: none;
}



@media only screen and (max-width:1024px) {
    .footer_main_sub_division {
        padding: 0px;
    }
}





@media only screen and (max-width:576px) {


    .footer_main_sub_division {
        display: block;
        --bs-gutter-x: 0rem !important;
        padding: 0px 20px;
    }


    .footer_main_about_us_division {
        padding: 0px;
    }

    .footer_main_usefull_links {
        display: flex;
        justify-content: start !important;
    }


    .footer_logo_img {
        padding-top: 0px;
        text-align: start;
        width: 50%;
        height: 50px;
    }

    .footer_text_aerrow_division {
        width: 100%;
        padding: 7px 10px 7px 0px;
    }

    .google_play {
        height: 40px;
        margin-right: 10px;
    }

    .app_store {
        height: 40px;
    }

    .footer_first_title {
        font-size: 1.2rem;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .footer_quick_links_items {
        font-size: 1rem !important;
    }

    .footer_contact_us {
        font-size: 1rem !important;
    }

    .footer_first_main_section {
        display: block;
    }

    .footer_second_main_section {
        margin-top: 20px !important;
    }

    .footer_usefull_links_items {
        display: flex;
        justify-content: start;
    }

    .footer_middle_division {
        padding: 0px 10px;
    }

    .footer_main_division {
        padding-left: 10px;
    }

    .footer_second_row {
        margin-top: 20px;
    }


    .footer_copyright_division {
        text-align: center;
        background-color: var(--secondary-color);
        font-weight: 600;
        font-size: 15px;
    }

    .footer_copyright {
        font-weight: 600;
        font-size: 15px;
    }




}



@media screen and (max-width:768px) {
    .email_footer {
        display: flex;
        margin-top: 10px;
        margin-left: 30px;
    }

    .footer_subscribe_title {
        font-size: 1.5rem;
        margin-left: 30px;
    }

}


@media screen and (max-width:576px) {}