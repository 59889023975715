.address_breadcrums_page {
    display: flex;
}


.address_breadcrums_path {
    color: black;
    font-size: 14px;
    font-weight: 200;
}



.address_breadcrums_path_icon {
    color: var(--second-secondary);
    margin-left: 15px;
    font-size: 14px;
}

@media only screen and (max-width:425px) {
    .address_breadcrums_path_icon {
        margin-left: 5px;
    }
}

.address_breadcrums_path_details {
    color: black;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

@media only screen and (max-width:425px) {
    .address_breadcrums_path_details {
        margin-left: 0px;
    }
}

.address_breadcrums_extra_division {
    padding-top: 10px;
}




@media only screen and (max-width:1024px) {
    .address_breadcrums_page_form {
        padding: 0px;
        margin-left: 10px;
        /* margin-right: 180px; */

    }
}

@media only screen and (max-width:425px) {
    .address_breadcrums_page_form {
        display: contents;
        padding: 0% 0%;
    }
}

.address_breadcrums_page_title {
    color: black;
    font-size: 25px;
    font-weight: 300;
}

@media only screen and (max-width:425px) {
    .address_breadcrums_page_title {
        margin-left: 0%;
    }
}


@media only screen and (min-width:1024px) and (max-width:1439px) {
    .address_breadcrums_page_title {
        margin-left: -3%;
        margin-top: 0px;
    }
}

.address_breadcrums_add_new_button {
    display: flex;
    align-items: center;
    justify-content: end;
}

.address_breadcrums_plus_icon {
    cursor: pointer;
    color: var(--secondary-color);
    background-color: var(--theme-default);
    font-weight: 500;
    padding: 10px;
}




.address_breadcrums_default {
    color: white;
    background-color: var(--theme-default);
    width: max-content;
    padding: 5px;
    font-weight: 700;
}

.address_breadcrums_user_details {
    display: flex;
    margin-top: 10px;
    color: var(--black-background-color);
}

.address_breadcrums_nick_address {
    color: var(--arihant-color-border-second);
}

.address_breadcrums_name {
    border-right: 1px solid var(--arihant-second-border-color);
    width: max-content;
    padding-right: 10px;
}

.address_breadcrums_mobile_number {
    padding-left: 10px;
}

.address_breadcrums_full_address {
    font-weight: 600;
    font-size: 17px;
    margin-top: 15px;
    width: 100%;
}

.address_breadcrums_checkbox_button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.address-breadcrums-form-check-label {
    font-weight: 300;
    font-size: 12px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width:320px) {
    .address-breadcrums-form-check-label {
        margin-left: 0px;
    }
}

.form-check-input {
    border: 1px solid var(--secondary-color);
    width: 20px;
    height: 20px;
    margin-top: 0em;
    vertical-align: top;
    background-color: var(--black-background-color);
}

.form-check-input:focus-visible {
    border: transparent;
}

.address_breadcrums-form-check {
    display: flex;
    margin-right: 0px;
}

.address_breadcrums_address_type {
    padding-left: 20px;
    font-weight: 300;
    font-size: 15px;
}

@media only screen and (max-width:320px) {
    .address_breadcrums_address_type {
        padding-left: 0px;
    }
}

.address_breadcrums_type_home {
    padding-left: 5px;
    font-weight: 600;
    font-size: 15px;
}


.address_breadcrums_delete {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
    height: 40px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;

}

.address_breadcrums_update {
    color: var(--secondary-color);
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
    height: 40px;
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
}

@media only screen and (max-width:768px) {
    .address_breadcrums_page_title {
        margin: 10px;
    }
}


.addressbox_main_div {
    display: flex;
    align-items: center;
    border: 1px solid black;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
}



.address_breadcrumb_grid_container {
    margin-top: 20px;

}

@media only screen and (max-width:768px) {

    .addressbox_main_div {
        display: block;
    }
}