.clear {
    clear: both;
  }
  
  .checkBox {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px var(--ecom-admin-font-color);
  }
  
  .checkBox div {
    width: 60px;
    height: 60px;
    background-color: var(--ecom-admin-font-color);
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }
  
  .checkBox input[type=checkbox]:checked + div {
    left: -10px;
    top: -10px;
  }
  
  .checkBox input[type=checkbox] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }
  
  .transition {
    transition: 300ms ease;
  }