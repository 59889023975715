.admin_login_left {
    background-image: url(../../../../images/adminback.jpg);
    background-size: cover;
    color: var(--ecom-admin-secondary-color);
    display: table;
    padding: 50px;
    position: relative;
    width: 700px;
    z-index: 1;
}


.admin_login_left:after {
    background-color: var(--ecom-admin-font-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.admin_policy_div div {
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
}

.admin_policy_div div:hover {
    transform: scale(1.1);
}


.adminlogin_main_sub_division {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.adminlogin_sub_main_division {
    padding: 20px;
}

.adminlogin_text_search_field {
    height: 50px;
}

.adminlogin_text_search_field:focus {
    border: 1px solid var(--ecom-admin-font-color) !important;
}

.adminlogin_login_title_name {
    font-size: 30px;
    font-weight: 600;
    color: var(--ecom-admin-font-color);
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.adminlogin_label_name {
    color: var(--ecom-admin-font-color);
    font-size: 18px;
    font-weight: 400;
}

.adminlogin_login_btn_division {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.adminlogin_login_btn {
    background-color: var(--ecom-admin-font-color);
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    color: var(--ecom-admin-secondary-color);
    font-size: 18px;
}


.admin_login_email_field_division1 {
    width: 100%;
}

.admin_login_email_field {
    width: 100%;
    border: none !important;
}

.admin_login_input_main {
    width: 395px;
}

.admin_login_password_field_division {
    display: flex;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    background-color: var(--ecom-admin-secondary-color);
    height: 52px;
}

.admin_login_password_field_division:focus {
    background-color: var(--ecom-admin-second-secondary-color);
    border: 1px solid var(--ecom-admin-font-color);
}

.adminlogin_text_password_field {
    border: none !important;
    height: 50px;
}

.adminlogin_text_password_field:focus {
    border: none !important;
}

.admin_login_password_eyes_icon {
    display: flex;
    align-items: center;
    /* margin-right: -20px; */
}


.admin_password_eye {
    display: flex;
}

.admin_login_password_eye {
    align-items: center;
    display: flex;
}

.eye_icon {
    color: var(--ecom-admin-second-secondary-color);
    top: 20%;
    right: 1%;
    cursor: pointer;
    z-index: 1;
}

.forget_password_page {
    cursor: pointer;
    font-size: 15px;
}