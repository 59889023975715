


.order_received_main_division{
    margin: 100px 180px;
}


@media only screen and (min-width:426px) and (max-width:1024px){
    .order_received_main_division{
        margin: 100px 45px;
    }  
}

@media only screen and (min-width:320px) and (max-width:426px){
    .order_received_main_division{
        margin: 100px 20px;
    }  
}
.order_received_main_title{
    color: var(--secondary-color);
    font-size: 30px;
    font-weight: 200;
    margin-bottom: 50px;

}
.order_recevied_go_to_home{
    color: var(--theme-default);
    width: 20%;
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--theme-default);
    font-weight: 600;
    font-size: 18px;
    cursor: pointer; 
}
.order_recevied_order_msg{
    color: var(--secondary-color);
    margin-top: 10px;
    font-size: 22px;
    font-weight: 300;
}

.order_recevied_sub_main_division{
    margin: 20px 0;
    padding: 0 30px 30px 30px;
}

.order_recevied_order_detail_division{
    margin-top: 30px;
}

.order_recevied_order_number_title{
    color: var(--secondary-color);
    font-size: 14px;
    font-weight: 300;
}

.order_recevied_order_number{
    color: var(--secondary-color);
    font-weight: 500;
}

.order_recevied_order_price{
    color: var(--theme-default);
    font-weight: 500;
}

.order_recevied_order_details_title{
    color: var(--theme-default);
    font-size: 22px;
    margin-top: 20px;
}

.order_recevied_order_details_main_division{
    margin-top: 20px;
    width: 100%;
}

.order_recevied_product_title{
    color: var(--theme-default);
    font-weight: 500;
    padding: 10px;
    width: 60%;
}

.order_recevied_product_total{
    color: var(--theme-default);
    font-weight: 500;
    padding: 10px;
}

.order_recevied_total_title{
    color: var(--theme-default);
    font-weight: 500;
    padding: 10px;
}

.order_recevied_product_info{
    padding: 10px;
    width: 60%;
}

.order_recevied_product_name{
    color: var(--secondary-color);
    font-weight: 300;
    font-size: 15px;
}

.order_recevied_size_name{
    color: var(--secondary-color);
    font-weight: 500;
}

.order_recevied_price_method{
    color: var(--secondary-color);
    padding: 10px;
    font-size: 15px;
    width: 60%;
}

.order_recevied_product_price{
    color: var(--theme-default);
    padding: 10px;
    align-items: center;
    display: flex;
    font-weight: 500;
}

.order_recevied_product_delivery{
    color: var(--secondary-color);
    padding: 10px;
    align-items: center;
    display: flex;
}

.order_recevied_billing_details{
    color: var(--secondary-color);
    margin: 10px 0px;
}

.order_recevied_billing_details_address{
    color: var(--secondary-color);
}

.cMaEaj {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: var(--theme-default) !important;
    color: var(--secondary-color);
    -webkit-animation: iAjNNh 1s ease;
    animation: iAjNNh 1s ease;
}

.iWFOsV:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 2.375rem;
    background: var(--theme-default) !important;
    -webkit-animation: gHpPbo 1s ease;
    animation: gHpPbo 1s ease;
}