.comparepage_main_division {
    margin: 150px;
}

.comparepage_App {
    display: flex;
    justify-content: center;
}

.comparepage_product_card {
    margin: 0px 10px;
    background-color: #353535;
    padding: 10px;
}

.comparepage_storage {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.comparepage_card_productname {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}

.comparepage_card {
    width: 295px;
    height: 415px;
}

.comparepage_card .comparepage_imgBx {
    width: 100%;
    display: flex;
    justify-content: center;
}

.comparepage_imgBx {
    width: 180px;
    height: 180px;
    object-fit: contain;
}

.comparepage_contentBx {
    width: 100%;
    text-align: center;
}

.size {
    display: flex;
    justify-content: center;
    align-items: center;
}

.comparepage_card_origanl_price {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}

.comparepage_review_division {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 20px;
}

.comparepage_card_wishlist {
    display: flex;
    justify-content: end;
}

.comparepage_close_icon {
    color: white;
    font-size: 20px;
}

.comparepage_product_id {
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
}

.comparepage_offer_main_price {
    color: white;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 600;
}

.comparepage_card_origanl_price {
    color: #9a9a9a;
    margin-top: 5px;
    margin-left: 5px;
}

.comparepage_offer_tag {
    background-color: white;
    color: #e11f48;
    width: max-content;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}

.comparepage_offer_division {
    display: flex;
    justify-content: center;
    margin: 5px;
}

.comparepage_accordian_main_division {
    margin-top: 20px;
}

.comparepage_product_details_title {
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.comparepage_customer_rating {
    color: white;
    display: flex;
    align-items: center;
}

.comparepage_rating_reviews_division {
    display: flex;
    /* margin-top: 10px; */

}

.comparepage_main_main_review_page_division {
    display: flex;
    width: 100%;
}

.comparepage_first_main_division {
    margin-top: 20px;
}

.comarepage_review_user_name {
    color: white;
}

.comparepage_sub_main_review_division {
    width: 33.33%;
    border-right: 1px solid white;
    margin-right: 15px;
}



/* key_features_css_start */

.comparepage_specifications_division {
    background-color: black;
    border: 2px solid var(--black-background-color);
    border-radius: 10px;
    padding: 5px 35px;
    margin: 20px 0px;
}

.comparepage_specifications {
    background-color: transparent !important;
}

.comparepage_specifications_feild {
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}

.comparepage_sub_main_division {
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.comparepage_details_title {
    color: white;
    font-size: 15px;
    font-weight: 600;
    width: 10%;
}

.comparepage_details {
    color: white;
    width: 30%;
    border-right: 1px solid white;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.comparepage_details_withoutborder {
    color: white;
    width: 30%;
    /* border-right: 1px solid white; */
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.comparepage_details1 {
    color: white;
    width: 30%;
    display: flex;
    align-items: center;
}

.comparepage_specifications_detail {
    color: white;
    font-weight: 700;
    font-size: 14px;
}


/* key_features_css_end */


/* mobile_category_css_start */

.comparepage_overview_division {
    background-color: black;
    border: 2px solid var(--black-background-color);
    border-radius: 10px;
    padding: 5px 35px;
    margin: 20px 0px;
}

.comparepage_product_overview {
    background-color: transparent !important;
}

.comparepage_overview_feild {
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}

.overview_detail_division {
    padding: 10px 0px;
}

.overview_detail_division>.table {
    color: white !important;
}

.arihantProductPageExpandIcon {
    color: white !important;
}

/* mobile_category_css_end */


/* product_review_css_start */

.comparepage_accordian_review_division {
    background-color: black;
    border: 2px solid var(--black-background-color);
    border-radius: 10px;
    padding: 5px 35px;
    margin: 20px 0px;
    margin-bottom: 80px;
}

.comparepage_product_review {
    background-color: transparent !important;
}

.comparepage_review_feild {
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0px;
}

/* product_review_css_end */


@media only screen and (max-width:768px) {

    .comparepage_card {
        width: 215px;
        height: 360px;
    }

    .comparepage_imgBx {
        height: 150px;
    }

    .comparepage_main_division {
        margin: 100px 0px 0px 0px;
    }

    .comparepage_details_title {
        width: 20%;
    }

    .comparepage_details_withoutborder {
        width: 20%;
    }
}

@media only screen and (max-width:430px) {

    /* .comparepage_card {
        width: 70px;
      
    }

    .comparepage_card_productname{
        font-size: 11px;
    }

    .comparepage_imgBx{
        height: 87px;
    }

    .comparepage_product_id {
        font-size: 11px;
    } */

    .comparepage_App {
        overflow: scroll;
        justify-content: start;
    }

    .comparepage_main_division {
        margin: 115px 0px 0px 0px;
    }

    .comparepage_specifications_division {
        padding: 5px 0px;
    }

    .comparepage_accordian_review_division {
        padding: 5px 0px;
    }
}