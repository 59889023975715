.emptycart_btn {
    background: var(--theme-default);
    color: white;
    border-radius: 0px;
}

.emptycart_btn:hover {
    color: white;
    background: var(--theme-default);

}

.image_size_2 {
    width: 250px;
    height: 250px;
}

.emptycart_img {
    width: 30%;
}



.checkout_wishlist_remove_btn {
    padding: 8px 20px;
    color: var(--secondary-color);
    margin: 0px 20px 0px 0px;
    background: var(--btn-back);
    border: 1px solid var(--secondary-color);
    font-size: 12px;
    border-radius: 8px;
    font-weight: 700;
}


.checkout_billing_address_title {
    font-size: 16px;
    font-weight: 700;
}



.checkout_full_address {
    font-weight: 400;
    font-size: 13px;
    word-break: break-all;
}

.checkout_full_default_address {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
    word-break: break-all;
}




.form-check {
    margin-bottom: 20px;
}

.form-check-input {
    border: 1px solid white;
    width: 20px;
    height: 20px;
    background-color: var(--black-background-color);
    margin-right: 5px;
}

.form-check-input:focus {
    border-color: rgb(228, 228, 228);
    box-shadow: none;
}


.form-check-input:focus-visible {
    border: transparent;
}

.checkout_address_divison {
    display: flex;
}

.checkout_default_address {
    margin-top: 10px;
    color: var(--theme-default);
    margin-bottom: 0px;
    font-size: 16px;
}

.checkout_change_address_btn {
    color: var(--black-background-color);
}

.checkout_change_address_btn:focus {
    box-shadow: none;
}



.checkout_delivery_time {
    font-size: 15px;
    font-weight: 700;
}

.checkout_delivery_time1 {
    font-size: 15px;
    font-weight: 700;
}

.checkout_delivery_time2 {
    font-size: 15px;
    font-weight: 700;
}

.checkout_select_address::-webkit-scrollbar {
    display: none;
}

.checkout_select_address {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
}


.checkout-form-check {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    padding-bottom: 20px;
}


.cart_page_product_right_side_apply_code {
    background-color: #F2F1F1;
    /* width: 389px; */
}



.input_cart_page {
    height: 42px;
    border: 1px solid var(--theme-default);
}

.input_cart_page:focus {
    border: 1px solid var(--theme-default);
}

.apply_cart_button_submit_cart_page {
    background-color: var(--theme-default);
    color: var(--secondary-color);
}

.input_with_button_cart_page {
    justify-content: space-between;
    display: flex;
}

.apply_cart_button_submit_cart_page:hover {
    color: var(--secondary-color) !important;
}

.apply_cart_button_submit_cart_page:focus {
    border: none !important;
    box-shadow: none !important;
}

.main_apply_div_cart_page {
    padding: 20px;
    border-radius: 18px;
}

.order_at_cart_page {
    font-size: 15px;
    margin-bottom: 10px;
    padding-left: 20px;
    font-weight: 600;
    color: black;
}







.left_second_div {
    width: 100%;
    border-radius: 12px;
}

.second_main_div {
    padding: 10px;
    box-shadow: 0px 0px 5px 0px black;
    margin-bottom: 10px;
}

.image_section {
    display: flex;
}

.necklace_img {
    width: 146px;
    height: 162px;
}


.description_section {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.product_name_checkout {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
}

.item_price {
    font-size: 25px;
    font-weight: 500;

}

.delete_move_to_whislist {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.delete_icon {
    cursor: pointer;
    border-right: 1px solid black;
}

.move_to_whislist {
    cursor: pointer;
}


.proceed_to_buy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--theme-default);
    color: white;
    padding: 5px;
}




.save_price {
    display: flex;
    font-size: 1rem;
    margin: 0px 0px 0px 5px;
}




.quantity_2 {
    display: flex;

    align-items: center;
}

.pl_mi_div {

    cursor: pointer;
    padding: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
}

.button_close {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    align-items: flex-end;
}

.order_summary_detail {
    font-size: 15px;
    padding: 12px 12px 8px;
}

.sub_total {
    margin-bottom: 17px;
    color: var(--theme-default-two);
}

.price_value {
    float: right;
}

.coupon_discount {
    margin-bottom: 17px;
    color: var(--theme-default-two);
}

.shiping_standard {
    margin-bottom: 17px;
    color: var(--theme-default-two);
}



.total_price_1 {
    font-size: 18px;
}

.total_price {
    margin-bottom: 17px;
}

.you_price {
    color: var(--theme-default);
}

.you_value {
    color: var(--theme-default);
    float: right;
}

.place_order {
    font-size: 1.1rem;
}


.button_order {
    font-size: 14px;
    padding: 8px 11px;
    cursor: pointer;
    background-color: white;
    border: 1PXX solid var(--theme-default);
    color: var(--theme-default);
    margin-right: 10px;
}


@media screen and (max-width:425px) {

    .checkout_full_default_address {
        margin: 0px 0 0px 0px;
    }

    .checkout_billing_address_title {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .checkout_wishlist_remove_btn {
        margin-right: 5px;
        padding: 8px 15px;
        font-size: 10px;
    }

    .checkout_left_part {
        border-right: none;
    }

    .checkout_address_divison {
        margin-bottom: 20px;
    }

    .image_section {
        display: block;
    }

    .description_section {
        margin-left: 0px;
    }
}


@media only screen and (max-width:320px) {
    .checkout_billing_address_title {
        font-size: 14px;
        font-weight: 500;
    }
}


@media screen and (max-width:375px) {
    .total_price_1 {
        font-size: 15px;
    }
}

@media only screen and (max-width:576px) {
    .cart_page_product {
        display: block;
    }
}


.quantity-select {
    width: 30%;
    margin-right: 43px;
}

.you-save {
    color: var(--theme-default);
}