

.allproducts_title {
    align-self: center;
    font-weight: 700;
    font-size: 22px;
}

.allproducts_addnew_button {
    width: 100px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.bgColorRed {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_addnew_button:focus {
    box-shadow: none;
}

.allproducts_addnew_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allproducts_search_feild {
    width: 250px;
    border: 1px solid var(--ecom-admin-border);
}

.allproducts_search_feild:focus {
    border: 1px solid var(--ecom-admin-second-secondary-color);
    box-shadow: none;
}

.allproducts_search_button {
    font-size: 16px;
    font-weight: 500;
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_search_button:hover {
    color: var(--ecom-admin-secondary-color);
}

.allproducts_search_button:focus {
    box-shadow: none;
}

.allproducts_bulkaction_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_bulkaction_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allproducts_bulkaction_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_bulkaction_apply_button {
    margin: 0px 5px;
    padding: 0px 25px;
}

.allproducts_bulkaction_apply_button:focus {
    box-shadow: none;
}

.allproducts_select_category {
    width: 200px;
    border: 1px solid var(--ecom-admin-border);
}

.allproducts_select_category:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none;
}

.allproducts_filterbyproduct_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_filterbyproduct_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allproducts_filterbyproduct_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_filterbystockstatus_dropdown {
    background-color: var(--ecom-admin-secondary-color);
    border: 1px solid var(--ecom-admin-border);
    width: 200px;
}

.allproducts_filterbystockstatus_dropdown:focus {
    border: 1px solid var(--ecom-admin-font-color);
    box-shadow: none
}

.allproducts_filterbystockstatus_dropdown_menu:hover {
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
}

.allproducts_apds_division {
    display: flex;
}
.active_allproducts_apds{
    background-color: var(--ecom-admin-font-color);
    color: var(--ecom-admin-secondary-color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
}
.active_allproducts_apds span{
    padding-left: 5px;
}
.allproducts_apds {
    color: var(--ecom-admin-font-color);
    font-size: 16px;
    align-self: center;
    margin-left:5px;
}

.allproducts_apds span {
    color: var(--ecom-admin-border);
    margin: 0px 5px;
}

span .all_products_data_table{
    background-color: var(--ecom-admin-font-color)!important;
}
/* .all_products_tables{
    background-color: red;
    width: 100%;
} */
