.App {
    text-align: center;
}

.form-container {
    padding: 20px;
    border-radius: 8px;
    text-align: left;
}



.submit_button {
    background-color: var(--ecom-admin-font-color);
    color: white;
    font-weight: bold;
}

.submit_button:hover {
    background-color: var(--ecom-admin-font-color);
    color: white;
    font-weight: bold;
}

.reset_button {
    background-color: var(--ecom-admin-font-color);
    color: white;
    font-weight: bold;
}

.reset_button:hover {
    background-color: var(--ecom-admin-font-color);
    color: white;
    font-weight: bold;
}

button:focus {
    outline: none;
}