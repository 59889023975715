
.adminpanel{
    height: 100%;
    margin-top: 130px;

}

.adminpanel_outletmain_div{
    width: 100%;
}

@media only screen and (max-width:1024px) {
     
    .adminpanel_outletmain_div{
        width: 100%;
    }
}

li {
    list-style: none;
}