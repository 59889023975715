.orderadmin_main_division {
  /* width: 80%; */
  margin: 30px 20px;
}

.orders_title {
  align-self: center !important;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 22px;
  color: var(--ecom-admin-font-color);
}

.orders_addnew_button {
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--ecom-admin-font-color) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.bgColorRed {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.orders_addnew_button:focus {
  box-shadow: none;
}

.orders_addnew_button:hover {
  color: var(--ecom-admin-secondary-color);
}

.orders_search_filter_by_register {
  width: 250px;
  border: 1px solid var(--ecom-admin-border);
}

.orders_search_filter_by_register:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.orders_search_button {
  font-size: 16px;
  font-weight: 500;
  background-color: var(--ecom-admin-font-color) !important;
  color: var(--ecom-admin-secondary-color) !important;
}

.orders_search_button:hover {
  color: var(--ecom-admin-secondary-color);
}

.orders_search_button:focus {
  box-shadow: none;
}

.orders_bulkaction_dropdown {
  background-color: var(--ecom-admin-secondary-color);
  border: 1px solid var(--ecom-admin-border);
  width: 250px;
}

.orders_bulkaction_dropdown:focus {
  border: 1px solid var(--ecom-admin-font-color);
  box-shadow: none;
}

.orders_bulkaction_dropdown_menu:hover {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
}

.order_bulkaction_apply_button {
  margin: 0px 5px;
  padding: 0px 25px;
}

.order_bulkaction_apply_button:focus {
  box-shadow: none;
}
.order_filter_by_change_button {
  margin: 0px 5px;
  padding: 0px 25px;
}
.order_filter_by_change_button:focus {
  box-shadow: none;
}

.order_ac_division {
  display: flex;
  gap: 10px;
}
.active_order_ac {
  background-color: var(--ecom-admin-font-color);
  color: var(--ecom-admin-secondary-color);
  width: max-content;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.active_order_ac span {
  padding-left: 5px;
}
.order_ac {
  color: var(--ecom-admin-font-color);
  font-size: 16px;
  align-self: center;
  margin-left: 5px;
  cursor: pointer;
}
.order_ac span {
  color: var(--ecom-admin-border);
  margin: 0px 5px;
}

/* download invoice btn */

.Download-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  padding: 8px;
  color: var(--ecom-admin-secondary-color);
  background: #483a15;
  border: none;
  letter-spacing: 0.05em;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.Download-button svg {
  margin-right: 8px;
  width: 25px;
}

/* download invoice btn */
