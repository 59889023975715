

.comaparepopup_main_division{
    background-color: black;
    padding: 20px;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.comparepopup_sub_main_division{
    display: flex;
    justify-content: center;
}

.comparepopup_close_icon{
    color: white;
    font-size: 22px;
    display: flex;
    justify-content: end;
}

.comparepopup_sub_division{
    width: 100%;
    display: flex;
}

.comparepopup_first_division{
    width: 70%;
    display: flex;
}

.comparepopup_select_img_card_division{
    border: 1px solid #353535;
    width: 35%;
    height: 180px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
}

.comparepopup_second_division{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comparepopup_compare_btn{
    background-color: #e11f48;
    color: white;
    border-radius: 10px;
    padding: 10px;
    width: 160px;
    font-size: 15px;
    font-weight: 600;
    border: none;
}

.comparepopup_clearall_btn{
    background-color: black;
    border: 1px solid white;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    width: 160px;
    font-size: 15px;
    font-weight: 600;
}


/* card css */


.comparepopup_img_card_division{
    background-color: #353535;
    width: 100%;
    height: 180px;
    padding: 5px;
}

.comparepoup_card_close_btn{
    display: flex;
    justify-content: end;
    color: white;
    font-size: 15px;
}

.comparepopup_compare_Card_second_division{
    display: flex;
    justify-content: center;
    height: 70px;
    width: 70px;
}

.comparepopup_img_mobile{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    object-fit: contain;
}

.comparepopup_item_title_name{
    color: white;
    font-size: 14px;
    margin-top: 5px;
}

.comparepopup_item_price{
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}