
/* accordian_css_start */


.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: var(--ecom-admin-font-color) !important;
}

.s {
  padding: 10px;
}

.css-1nt25v5-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #8f7d4f !important;
}




/* sidebar three start */


.sidebar_three {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--ecom-admin-font-color);
  transition: all 0.2s ease-in-out 0s;
  padding: 10px;
  overflow: scroll;
  z-index: 100;
}

.sidebar_three::-webkit-scrollbar{
  display: none;
}

.activee {
  position: relative;
  margin-bottom: 10px;
}

.activee:hover {
  background-color: #F6F3F9;
}

.activee:hover a {
  color: var(--ecom-admin-font-color);
}

.activee a {
  background-color: white;
  color: var(--ecom-admin-font-color);
}

.non_activee:hover a {
  color: var(--ecom-admin-font-color);
}

.non_activee {
  margin-bottom: 10px;
}

.non_activee:hover {
  background-color: #F6F3F9;
}

.link {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: #f1f1f1;
  padding: 15px 15px;
  list-style-type: none !important;
  margin-bottom: 10px;
  cursor: pointer;
}

.link:hover {
  color: #f1f1f1;
}

.span_li {
  display: inline-block;
  margin-left: 30px;
  white-space: nowrap;
}

.menu_icon {
  margin-left: 4px;
}


.feature_div {
  text-align: start;
  margin-left: 50px;
}

.feature_div div {
  padding: 5px 15px;
}

.ul_element {
  position: relative;
  color: #f1f1f1;
  padding: 10px 15px;
  list-style: none;
}

.ul_element:hover {
  background-color: #f1f1f1;
}

.ul_element:hover .linktitle {
  color: var(--ecom-admin-font-color) !important;
}

.ul_element_activee {
  color: var(--ecom-admin-font-color) !important;
  background-color: var(--ecom-admin-secondary-color);
  padding: 10px 15px;
}

.ul_element_activee .linktitle {
  color: var(--ecom-admin-font-color) !important;
}


.linktitle {
  color: var(--ecom-admin-secondary-color);
}

/* sidebar three start */